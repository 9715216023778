import * as React from 'react';

type Props = {
  strokeColor?: string;
};

export const BagSvg: React.FC<Props> = ({strokeColor = '#60708E'}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none'>
      <path
        fill={strokeColor}
        d='M17.312 17.274 16.248 5.569A.626.626 0 0 0 15.624 5H13.75V3.75a3.73 3.73 0 0 0-1.095-2.655A3.755 3.755 0 0 0 6.25 3.75V5H4.374a.624.624 0 0 0-.622.569L2.688 17.274a2.505 2.505 0 0 0 .644 1.912A2.504 2.504 0 0 0 5.178 20h9.644c.701 0 1.373-.296 1.846-.814a2.51 2.51 0 0 0 .644-1.912ZM7.499 3.75c0-1.379 1.122-2.5 2.5-2.5a2.49 2.49 0 0 1 2.5 2.5V5h-5V3.75Zm8.245 14.594a1.24 1.24 0 0 1-.924.406H5.178c-.355 0-.684-.145-.922-.407a1.233 1.233 0 0 1-.322-.957L4.946 6.25h1.303v1.875a.625.625 0 0 0 1.25 0V6.25h5v1.875a.625.625 0 0 0 1.25 0V6.25h1.304l1.012 11.137c.033.354-.08.694-.32.957Z'
      />
    </svg>
  );
};
