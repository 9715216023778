import React from 'react';
import {theme} from '../constants';

import {text} from '../text';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {product} from '../product';

type Props = {
  item: any;
  isLast?: boolean;
  version?: number;
};

export const ProductCard: React.FC<Props> = ({isLast, item, version = 1}) => {
  const navigate = hooks.useAppNavigate();

  // Home > BestSeller item //
  if (version === 1) {
    return (
      <div
        style={{
          width: 300,
          borderRadius: 12,
          cursor: 'pointer',
          marginRight: isLast ? 20 : 14,
        }}
        onClick={() => navigate('/product', {state: {item}})}
      >
        <custom.ImageBackground
          imageUrl={item.image}
          style={{
            width: 200,
            height: 250,
            borderRadius: 10,
            marginBottom: 14,
            position: 'relative',
          }}
        >
          <product.ProductInWishlist
            item={item}
            style={{position: 'absolute', padding: 14, right: 0, top: 0}}
          />
          <product.ProductInCart
            item={item}
            style={{position: 'absolute', right: 0, bottom: 0, padding: 14}}
          />
        </custom.ImageBackground>
        <product.ProductName
          item={item}
          numberOfLines={2}
          style={{marginBottom: 3}}
        />
        <product.ProductPrice item={item} />
      </div>
    );
  }

  // Shop Item
  if (version === 2) {
    return (
      <div
        style={{
          width: `calc(50% - 7.5px)`,
          cursor: 'pointer',
          borderRadius: 12,
          marginBottom: 20,
        }}
        onClick={() => {
          navigate('/product', {state: {item}});
        }}
      >
        <custom.ImageBackground
          imageUrl={item.image}
          style={{
            width: '100%',
            height: 170,
            borderRadius: 10,
            marginBottom: 6,
            position: 'relative',
            backgroundSize: 'contain',
            backgroundColor: theme.colors.imageBackground,
          }}
        >
          <product.ProductInWishlist
            item={item}
            style={{position: 'absolute', padding: 10, right: 0, top: 0}}
          />
          <product.ProductInCart
            item={item}
            style={{position: 'absolute', right: 0, bottom: 0, padding: 10}}
          />
        </custom.ImageBackground>
        <product.ProductName item={item} style={{marginBottom: 3}} />
        <product.ProductPrice item={item} />
      </div>
    );
  }

  // Featured Item
  if (version === 3) {
    const blockWidth = 138;

    return (
      <div
        style={{
          width: blockWidth,
          cursor: 'pointer',
          marginRight: isLast ? 20 : 14,
          borderRadius: 12,
        }}
        onClick={() => {
          navigate('/product', {state: {item}});
        }}
      >
        <custom.ImageBackground
          imageUrl={item.image}
          style={{
            width: blockWidth,
            height: 170,
            borderRadius: 10,
            marginBottom: 6,
            position: 'relative',
            backgroundSize: 'contain',
            backgroundColor: theme.colors.imageBackground,
          }}
        >
          <product.ProductInWishlist
            item={item}
            style={{position: 'absolute', padding: 10, right: 0, top: 0}}
          />
          <product.ProductInCart
            item={item}
            style={{position: 'absolute', padding: 10, right: 0, bottom: 0}}
          />
        </custom.ImageBackground>
        <product.ProductName item={item} style={{marginBottom: 2}} />
        <product.ProductPrice item={item} />
      </div>
    );
  }

  return null;
};
