import * as React from 'react';

import {theme} from '../../constants';

type Props = {
  bgColor?: string;
  iconColor?: string;
  onClick?: () => void;
};

export const OrderTabSvg: React.FC<Props> = ({
  onClick,
  bgColor = `${theme.colors.black}20`,
  iconColor = theme.colors.pastelMint,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={50}
      height={50}
      fill='none'
      onClick={onClick}
    >
      <circle cx={25} cy={25} r={25} fill={bgColor} />
      <g
        stroke={iconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
      >
        <path d='M21 36a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM32 36a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM13 15h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L35 20H18' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M12 14h24v23H12z' />
        </clipPath>
      </defs>
    </svg>
  );
};
