import React from 'react';

import {utils} from '../utils';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {product} from '../product';
import {ProductType} from '../types';

type Props = {item: ProductType; containerStyle?: any; isLast?: boolean};

export const WishlistItem: React.FC<Props> = ({
  item,
  isLast,
  containerStyle,
}): JSX.Element => {
  const navigate = hooks.useAppNavigate();

  const renderImage = (): JSX.Element => {
    return (
      <button
        style={{borderRadius: 12}}
        onClick={() => {
          navigate('/product', {state: {item}});
        }}
      >
        <custom.ImageBackground
          imageUrl={item.attachments.product_main}
          style={{
            width: 100,
            height: 100,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor: theme.colors.imageBackground,
          }}
        >
          {item.oldPrice && (
            <product.ProductSale
              item={item}
              style={{position: 'absolute', bottom: 0, left: 0, margin: 4}}
            />
          )}
        </custom.ImageBackground>
      </button>
    );
  };

  const renderDetails = (): JSX.Element => {
    return (
      <div
        style={{
          flex: 1,
          border: '1px solid #E7EBEB',
          borderWidth: '1px 0px',
          ...utils.rowSpcBtween(),
        }}
      >
        {/* Left content */}
        <div
          style={{
            paddingTop: 14,
            padding: '14px 0 10px 14px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <product.ProductName item={item} />
          <product.ProductPrice
            item={item}
            containerStyle={{marginBottom: 'auto'}}
          />
        </div>
        {/* Right content */}
        <div style={{...utils.columnCenterSpcBtween()}}>
          <product.ProductInWishlist
            item={item}
            version={1}
            style={{padding: '14px 20px'}}
          />
          <product.ProductInCart
            item={item}
            version={1}
            style={{padding: '14px 20px'}}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        ...containerStyle,
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 20,
        width: '100%',
        marginBottom: 14,
      }}
    >
      {renderImage()}
      {renderDetails()}
    </div>
  );
};
