import * as React from 'react';

export const GiftSvg: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none'>
      <path fill='#CFF5CE' d='M13.333 8.5v6.667H2.667V8.5' />
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M13.333 8.5v6.667H2.667V8.5'
      />
      <path
        fill='#CFF5CE'
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M14.667 5.167H1.333V8.5h13.334V5.167Z'
      />
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M8 15.166v-10'
      />
      <path
        fill='#CFF5CE'
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M8 5.167H5a1.667 1.667 0 0 1 0-3.333c2.333 0 3 3.333 3 3.333ZM8 5.167h3a1.667 1.667 0 1 0 0-3.333c-2.333 0-3 3.333-3 3.333Z'
      />
    </svg>
  );
};
