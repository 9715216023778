export const createOrder = async (orderData: {
  products: {id: number; quantity: number}[];
}) => {
  const token = sessionStorage.getItem('authToken');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

  try {
    const response = await fetch(`${API_BASE_URL}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error('Failed to create order');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Order creation error:', error);
    throw error;
  }
};

export const fetchOrders = async (limit: number, page: number) => {
  const token = sessionStorage.getItem('authToken');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

  try {
    const response = await fetch(
      `${API_BASE_URL}/my-orders?limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch orders error:', error);
    throw error;
  }
};
