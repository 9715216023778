import {FC, useState} from 'react';
import {CSSProperties} from 'react';
import {Link} from 'react-router-dom';

import {utils} from '../utils';
import {theme} from '../constants';

type Props = {
  to?: string;
  hover?: boolean;
  style?: CSSProperties;
  numberOfLines?: number;
  children: React.ReactNode;
  containerStyle?: CSSProperties;
};

const H4: FC<Props> = ({
  style,
  children,
  numberOfLines = 0,
}): JSX.Element | null => {
  return (
    <h4
      style={{
        fontSize: 18,
        lineHeight: 1.2,
        ...theme.fonts.Inter_500Medium,
        ...(numberOfLines
          ? (utils.numberOfLines(numberOfLines) as CSSProperties)
          : {}),
        ...style,
      }}
    >
      {children}
    </h4>
  );
};

export default H4;
