export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  coralRed: '#FF4040',
  aliceBlue: '#F2F6FF',
  textColor: '#4A6973',
  steelTeal: '#50858B',
  mainColor: '#23374A',
  pastelMint: '#CFF5CE',
  aliceBlue2: '#E8EFF4',
  ghostWhite: '#F4F7FC',
  mainYellow: '#F5C102',
  ghostWhite2: '#EDF1FA',
  lavenderMist: '#DBE3F5',
  bodyTextColor: '#333333',
  antiFlashWhite: '#E7EBEB',
  imageBackground: '#FAFAFA',
  transparent: 'transparent',
  secondaryTextColor: '#666666',
};

export const theme = {colors};
