import * as React from 'react';

const ServerSvg: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none'>
      <path
        fill='#CFF5CE'
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M13.333 9.834H2.667c-.737 0-1.334.596-1.334 1.333v2.667c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333v-2.667c0-.737-.597-1.333-1.334-1.333Z'
      />
      <path
        fill='#4A6973'
        d='M4.8 13.083a.705.705 0 0 1-.325.6.758.758 0 0 1-.38.12v.297h-.186v-.296a.831.831 0 0 1-.507-.212.636.636 0 0 1-.199-.472h.333a.48.48 0 0 0 .106.267.432.432 0 0 0 .267.142v-.914a2.325 2.325 0 0 1-.37-.128.614.614 0 0 1-.24-.205.629.629 0 0 1-.099-.373c0-.197.064-.36.192-.49a.782.782 0 0 1 .517-.216V10.9h.185v.303c.194.018.35.085.47.202a.697.697 0 0 1 .205.45h-.332a.433.433 0 0 0-.106-.231.418.418 0 0 0-.237-.139v.892c.151.042.273.084.367.128a.557.557 0 0 1 .24.201c.066.093.099.219.099.377Zm-1.281-1.192c0 .12.033.21.099.274a.752.752 0 0 0 .291.158v-.845a.454.454 0 0 0-.284.124.382.382 0 0 0-.106.289Zm.575 1.642a.427.427 0 0 0 .394-.431c0-.12-.034-.211-.103-.275a.724.724 0 0 0-.29-.157v.863Z'
      />
      <path
        fill='#CFF5CE'
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M13.333 1.833H2.667c-.737 0-1.334.597-1.334 1.334v2.667c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V3.167c0-.737-.597-1.333-1.334-1.333Z'
      />
      <path
        fill='#4A6973'
        d='M4.8 5.083a.705.705 0 0 1-.325.6.757.757 0 0 1-.38.12V6.1h-.186v-.296a.831.831 0 0 1-.507-.212.636.636 0 0 1-.199-.472h.333a.48.48 0 0 0 .106.267.431.431 0 0 0 .267.142v-.914a2.33 2.33 0 0 1-.37-.128.614.614 0 0 1-.24-.205.63.63 0 0 1-.099-.373c0-.197.064-.36.192-.49a.782.782 0 0 1 .517-.216V2.9h.185v.303c.194.018.35.085.47.202a.697.697 0 0 1 .205.45h-.332a.432.432 0 0 0-.106-.231.417.417 0 0 0-.237-.139v.892c.151.042.273.085.367.128a.557.557 0 0 1 .24.202c.066.092.099.218.099.376ZM3.519 3.891c0 .12.033.21.099.274a.753.753 0 0 0 .291.158v-.845a.454.454 0 0 0-.284.124.383.383 0 0 0-.106.289Zm.575 1.642a.428.428 0 0 0 .394-.432c0-.119-.034-.21-.103-.274a.724.724 0 0 0-.29-.157v.863Z'
      />
    </svg>
  );
};
export default ServerSvg;
