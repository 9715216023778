import React from 'react';
import {theme} from '../constants';

import {text} from '../text';

type Props = {
  item: any;
  numberOfLines?: number;
  style?: React.CSSProperties;
};

export const ProductName: React.FC<Props> = ({item, style, numberOfLines}) => {
  return (
    <text.T14
      numberOfLines={numberOfLines}
      style={{color: theme.colors.textColor, ...style}}
    >
      {item.name}
    </text.T14>
  );
};
