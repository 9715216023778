import * as React from 'react';

type Props = {
  circleColor?: string;
  iconColor?: string;
  fillColor?: string;
  strokeColor?: string;
};

export const MailSvg: React.FC<Props> = ({
  fillColor = '#FFDCDC',
  strokeColor = '#4A6973',
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none'>
      <path
        fill={fillColor}
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M2.667 3.167h10.666c.734 0 1.334.6 1.334 1.333v8c0 .733-.6 1.333-1.334 1.333H2.667c-.734 0-1.334-.6-1.334-1.333v-8c0-.733.6-1.333 1.334-1.333Z'
      />
      <path
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M14.667 4.5 8 9.167 1.333 4.5'
      />
    </svg>
  );
};
