import type {ProductType} from '../../types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type CartType = {
  total: number;
  delivery: number;
  discount: number;
  subtotal: number;
  promoCode: string;
  list: ProductType[];
  discountAmount: number;
  totalWeight: number;
};

const initialState: CartType = {
  total: 0,
  list: [],
  delivery: 0,
  discount: 0,
  subtotal: 0,
  promoCode: '',
  discountAmount: 0,
  totalWeight: 0, // Initialize weight to 0
};

type StateType = typeof initialState;

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (
      state: StateType = initialState,
      action: PayloadAction<ProductType>
    ) => {
      const product = action.payload;
      const existingProduct = state.list.find((item) => item.id === product.id);

      // If the product already exists in the cart
      if (existingProduct) {
        state.list = state.list.map((item) => {
          if (item.id === product.id) {
            // Increment quantity for the existing product
            item.quantity! += 1;
          }
          return item;
        });
      } else {
        // Add the new product to the cart with quantity 1
        state.list.push({
          ...product,
          quantity: 1,
        });
      }

      // Recalculate subtotal
      state.subtotal = state.list.reduce(
        (acc, item) =>
          acc +
          (item.default_sales_type === 'PACK'
            ? Number(item.price_per_pack)
            : Number(item.price)) *
            (item.quantity || 1),
        0
      );

      // Recalculate total weight
      state.totalWeight = Number(
        state.list
          .reduce(
            (acc, item) =>
              acc +
              (item.default_sales_type === 'PACK'
                ? Number(item.weight_per_pack) * (item.quantity || 1)
                : Number(item.weight) * (item.quantity || 1)),
            0
          )
          .toFixed(2)
      );

      // Recalculate total after applying the discount
      state.total = state.subtotal * (1 - state.discount / 100);

      // Update the discount amount
      state.discountAmount = Number((state.subtotal - state.total).toFixed(2));
    },

    removeFromCart: (state, action: PayloadAction<ProductType>) => {
      const inCart = state.list.find((item) => item.id === action.payload.id);

      if (inCart) {
        state.list.map((item) => {
          if (item.id === action.payload.id && (item.quantity as number) > 1) {
            if (item.quantity) {
              item.quantity -= 1;
            }
          } else if (item.id === action.payload.id && item.quantity === 1) {
            state.list.splice(state.list.indexOf(item), 1);
          }
          return item;
        });

        // Recalculate subtotal
        state.subtotal -= Number(
          action.payload.default_sales_type === 'PACK'
            ? Number(action.payload.price_per_pack)
            : Number(action.payload.price)
        );

        // Recalculate total weight
        state.totalWeight = Number(
          state.list
            .reduce(
              (acc, item) =>
                acc +
                (item.default_sales_type === 'PACK'
                  ? Number(item.weight_per_pack) * (item.quantity || 1)
                  : Number(item.weight) * (item.quantity || 1)),
              0
            )
            .toFixed(2) // Ensure 2 decimal places
        );

        state.discountAmount = Number(
          (state.subtotal - state.total).toFixed(2)
        );
        state.total -=
          (action.payload.default_sales_type === 'PACK'
            ? Number(action.payload.price_per_pack)
            : Number(action.payload.price)) *
          (1 - state.discount / 100);

        if (state.list.length === 0) {
          state.discount = 0;
          state.promoCode = '';
          state.totalWeight = 0; // Reset total weight if cart is empty
        }
      }
    },

    setDiscount: (state, action: PayloadAction<number>) => {
      if (state.list.length === 0) {
        state.discount = 0;
      } else {
        state.discount = action.payload;
      }
      const newTotal = state.subtotal * (1 - state.discount / 100);
      state.discountAmount = Number((state.subtotal - newTotal).toFixed(2));
      state.total = state.subtotal * (1 - state.discount / 100);
    },
    resetCart: (state) => {
      state.list = [];
      state.subtotal = 0;
      state.total = 0;
      state.discount = 0;
      state.promoCode = '';
      state.delivery = 0;
      state.discountAmount = 0;
    },
    setPromoCode: (state, action: PayloadAction<string>) => {
      state.promoCode = action.payload;
    },
  },
});

export const {addToCart, resetCart, setDiscount, setPromoCode, removeFromCart} =
  cartSlice.actions;

export default cartSlice.reducer;
