import React, {useEffect} from 'react';

import {hooks} from '../../hooks';
import {items} from '../../items';
import {RootState} from '../../store';
import {components} from '../../components';
import {text} from '../../text';

import {theme} from '../../constants';
import {actions} from '../../store/actions';
import {useNavigate} from 'react-router-dom';
export const Wishlist: React.FC = () => {
  const wishlist = hooks.useAppSelector(
    (state: RootState) => state.wishlistSlice.list
  );
  const navigate = hooks.useAppNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderBottomTabBar = () => {
    return <components.BottomTabBar />;
  };

  const renderContent = () => {
    return (
      <main style={{paddingTop: 20, paddingBottom: 64 + 20, marginTop: 180}}>
        {wishlist.map((item: any, index: number) => {
          const isLast = index === wishlist.length - 1;
          return <items.WishlistItem key={index} item={item} isLast={isLast} />;
        })}
      </main>
    );
  };

  const renderFooter = (): JSX.Element => {
    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };
  const renderemptyContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{
          padding: '0px 20px 94px 20px',
          marginTop: 52,
          paddingTop: '18vh',
        }}
      >
        <img
          src={require('../../assets/icons/28.png')}
          alt='order successful'
          style={{
            width: 123.39,
            marginBottom: 4,
          }}
        />
        <text.H2
          style={{
            marginBottom: 14,
            textTransform: 'capitalize',
            color: theme.colors.mainColor,
          }}
        >
          Your Wishlist is empty!
        </text.H2>
        <text.T16
          style={{
            whiteSpace: 'pre-line',
            color: theme.colors.textColor,
            marginBottom: 30,
          }}
        >
          Looks like you haven't chosen the{'\n'}items you like.
        </text.T16>
        <components.Button
          title='Browse products'
          colorScheme='light'
          onClick={() => {
            navigate('/Home');
          }}
          containerStyle={{marginBottom: 10}}
        />
      </main>
    );
  };
  const renderHeader = () => {
    return (
      <components.Header
        title=''
        burger={true}
        basket={true}
        line={true}
        goBack={false}
      />
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        {renderHeader()}
        {wishlist.length > 0 ? renderContent() : renderemptyContent()}{' '}
        {renderFooter()}
        {/* {renderBottomTabBar()} */}
      </div>
    </>
  );
};
