import React from 'react';

import {text} from '../../text';
import {hooks} from '../../hooks';
import {theme} from '../../constants';
import {components} from '../../components';

export const PhoneVerified: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='full-height'
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 60px)',
          justifyContent: 'center',
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          alt='order successful'
          src={require('../../assets/icons/19.png')}
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            marginBottom: 14,
            whiteSpace: 'pre-line',
            textTransform: 'capitalize',
            color: theme.colors.mainColor,
          }}
        >
          Your phone number has{'\n'}been successfully verified!
        </text.H2>
        <text.T16
          style={{
            marginBottom: 30,
            whiteSpace: 'pre-line',
            color: theme.colors.textColor,
          }}
        >
          Now you can enjoy all the features of our app.{'\n'}Thank you for
          choosing.
        </text.T16>
        <components.Button
          title='Done'
          onClick={() => {
            navigate('/TabNavigator');
          }}
          containerStyle={{width: '100%'}}
        />
      </main>
    );
  };

  return renderContent();
};
