import {getPlatform} from './getPlatform';
import {isMobileDevice} from './isMobileDevice';

import getTabs from './getTabs';
import rowCenter from './rowCenter';
import flexCenter from './flexCenter';
import rowSpcBtween from './rowSpcBtween';
import columnCenter from './columnCenter';
import numberOfLines from './numberOfLines';
import rowCenterWrap from './rowCenterWrap';
import rowWrapSpcBtween from './rowWrapSpcBtween';
import rowCenterFlexEnd from './rowCenterFlexEnd';
import rowCenterSpcBtween from './rowCenterSpcBtween';
import rowCenterSpcAround from './rowCenterSpcAround';
import columnCenterSpcBtween from './columnCenterSpcBtween';

export const utils = {
  getTabs,
  rowCenter,
  flexCenter,

  getPlatform,
  columnCenter,
  rowSpcBtween,
  numberOfLines,
  rowCenterWrap,
  isMobileDevice,
  rowWrapSpcBtween,
  rowCenterFlexEnd,
  rowCenterSpcBtween,
  rowCenterSpcAround,
  columnCenterSpcBtween,
};
