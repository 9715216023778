import React from 'react';
import {useLocation} from 'react-router-dom';

import {text} from '../../text';
import {hooks} from '../../hooks';
import {custom} from '../../custom';
import {theme} from '../../constants';
import {components} from '../../components';

export const SendEmailOtp: React.FC = () => {
  const navigate = hooks.useAppNavigate();
  let {state} = useLocation();

  const location = useLocation();
  const prevPath = location.state?.prevPath;

  const renderHeader = () => {
    return <components.Header goBack={true} title='Verify your email' />;
  };

  const renderContent = () => {
    return (
      <main style={{padding: '20px 20px 20px 20px', marginTop: 52}}>
        <text.T16 style={{marginBottom: 40, color: theme.colors.textColor}}>
          We have sent you an email with a verification code.
        </text.T16>
        <custom.InputField
          label='email'
          placeholder='oliviabennett@mail.com'
          containerStyle={{marginBottom: 20}}
        />
        <components.Button
          title={'Confirm'}
          onClick={() => {
            navigate('/Verification', {state: location.pathname});
          }}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
