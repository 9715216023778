import React from 'react';
import {CSSProperties} from 'react';

import {utils} from '../utils';
import {theme} from '../constants';

type Props = {
  style?: CSSProperties;
  numberOfLines?: number;
  children: React.ReactNode;
};

const H3: React.FC<Props> = ({
  style,
  children,
  numberOfLines = 0,
}): JSX.Element | null => {
  return (
    <h3
      style={{
        fontSize: 20,
        lineHeight: 1.2,
        ...theme.fonts.Inter_500Medium,
        ...(numberOfLines
          ? (utils.numberOfLines(numberOfLines) as CSSProperties)
          : {}),
        ...style,
      }}
    >
      {children}
    </h3>
  );
};

export default H3;
