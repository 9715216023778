import axios from 'axios';
import {useEffect, useState, FC} from 'react';

import {text} from '../../text';
import {URLS} from '../../config';
import {hooks} from '../../hooks';
import {utils} from '../../utils';
import {custom} from '../../custom';
import {theme} from '../../constants';
import {actions} from '../../store/actions';
import {components} from '../../components';

import {CategoryType, ProductType} from '../../types';

export const Categories: FC = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [productsData, setProductsData] = useState<ProductType[]>([]);
  const [categoriesData, setCategoriesData] = useState<CategoryType[]>([]);

  const getData = async () => {
    setLoading(true);
    try {
      const products = await axios
        .get(URLS.GET_PRODUCTS)
        .then((res) => res.data.products);

      const categories = await axios
        .get(URLS.GET_CATEGORIES)
        .then((res) => res.data.categories);

      setProductsData(products);
      setCategoriesData(categories);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const renderHeader = (): JSX.Element => {
    return (
      <components.Header
        burger={true}
        basket={true}
        line={true}
        goBack={true}
        title='Categories'
      />
    );
  };

  const renderCategories = (): JSX.Element => {
    return (
      <div
        style={{
          flexWrap: 'wrap',
          ...utils.rowCenterSpcBtween(),
          margin: '0 20px',
        }}
      >
        {categoriesData?.map((item: CategoryType) => {
          return (
            <button
              style={{
                position: 'relative',
                width: 'calc(50% - 7.5px)',
              }}
              onClick={() => {
                dispatch(actions.resetFilters());
                navigate('/Shop', {
                  state: {products: productsData, title: 'Shop'},
                });
              }}
            >
              <custom.ImageBackground
                key={item.id}
                style={{
                  justifyContent: 'flex-end',
                  height: 180,
                  width: '100%',
                  backgroundSize: 'cover',
                  marginBottom: 14,
                  borderRadius: 5,
                }}
                imageUrl={item.image}
              >
                <div
                  style={{
                    padding: '14px 12px',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      marginBottom: 'auto',
                      padding: '1px 8px',
                      borderRadius: 50,
                      backgroundColor: theme.colors.pastelMint,
                    }}
                  >
                    <text.T14 style={{color: theme.colors.steelTeal}}>
                      {item.qty}
                    </text.T14>
                  </div>
                  <text.T14 style={{color: theme.colors.mainColor}}>
                    {item.name}
                  </text.T14>
                </div>
              </custom.ImageBackground>
            </button>
          );
        })}
      </div>
    );
  };

  const renderContent = (): JSX.Element => {
    if (loading) return <components.TabLoader />;

    return (
      <main style={{paddingTop: 20, paddingBottom: 64 + 30, marginTop: 52}}>
        {renderCategories()}
      </main>
    );
  };

  const renderBottomTabBar = (): JSX.Element => {
    return <components.BottomTabBar />;
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {/* {renderBottomTabBar()} */}
    </>
  );
};
