import * as React from 'react';

type Props = {
  color?: string;
};

const AddSvg: React.FC<Props> = ({color = '#23374A'}) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.2}
      d='M8 3.333v9.334M3.333 8h9.334'
    />
  </svg>
);
export default AddSvg;
