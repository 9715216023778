import React from 'react';
import {Link} from 'react-router-dom';

import {text} from '../text';
import {utils} from '../utils';
import {svg} from '../assets/svg';
import {theme} from '../constants';

type Props = {
  title: string;
  icon?: JSX.Element;
  emailVerify?: boolean;
  titleStyle?: any;
  onClick?: () => void;
  goNavigation?: boolean;
  containerStyle?: any;
  navIcon?: boolean;
};

export const ProfileItem: React.FC<Props> = ({
  titleStyle,
  title,
  icon,
  onClick,
  navIcon = true,
  containerStyle,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        display: 'block',
        width: '100%',
        ...utils.rowCenter(),
        padding: '14px 20px',
        border: '1px solid #E7EBEB',
        borderRight: 0,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        ...containerStyle,
      }}
    >
      {icon}
      <text.T16
        style={{
          color: theme.colors.mainColor,
          marginLeft: 10,
          ...titleStyle,
        }}
      >
        {title}
      </text.T16>
      {onClick && navIcon && (
        <div style={{marginLeft: 'auto'}}>
          <svg.RightArrowSvg />
        </div>
      )}
    </button>
  );
};
