import {FC} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {theme} from '../constants';
import {components} from '../components';

export const MyPromocodesEmpty: FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderHeader = (): JSX.Element => {
    return <components.Header goBack={true} title='My promocodes' />;
  };

  const renderContent = (): JSX.Element => {
    return (
      <main
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 80px - 20px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          alt='order successful'
          src={require('../assets/icons/22.png')}
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            fontSize: 22,
            lineHeight: 1.2,
            color: theme.colors.mainColor,
            marginBottom: 14,
            textTransform: 'capitalize',
            whiteSpace: 'pre-line',
          }}
        >
          Your don’t have{'\n'}promocodes yet!
        </text.H2>
        <text.T14
          style={{
            color: theme.colors.textColor,
            marginBottom: 50,
            whiteSpace: 'pre-line',
          }}
        >
          Stay tuned for exclusive offers to elevate{'\n'}your plant shopping
          experience.
        </text.T14>
        <custom.InputField
          label='Enter the voucher'
          placeholder='Promocode2024'
          containerStyle={{marginBottom: 20}}
        />
        <components.Button
          title='submit'
          // onClick={() => {}}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
