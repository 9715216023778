import * as React from 'react';

type Props = {
  fillColor?: string;
  strokeColor?: string;
};

export const SmartPhoneSvg: React.FC<Props> = ({
  fillColor = '#FF7575',
  strokeColor = '#4A6973',
}) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none'>
      <path
        fill={fillColor}
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M11.333 1.833H4.667c-.737 0-1.334.597-1.334 1.334v10.666c0 .737.597 1.334 1.334 1.334h6.666c.737 0 1.334-.597 1.334-1.333V3.167c0-.737-.597-1.333-1.334-1.333Z'
      />
      <path
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M8 12.5h.007'
      />
    </svg>
  );
};
