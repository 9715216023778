import React from 'react';

import {text} from '../text';
import {utils} from '../utils';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {ReviewType} from '../types/ReviewType';

type Props = {
  item: ReviewType;
  isLast?: boolean;
};

export const ReviewItem: React.FC<Props> = ({item, isLast}): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        marginLeft: 20,
        flexDirection: 'row',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        border: '1px solid #E7EBEB',
        marginBottom: isLast ? 0 : 14,
        borderRight: '0px solid #E7EBEB',
      }}
    >
      {/* Left Block */}
      <div style={{...utils.columnCenter(), marginRight: 14}}>
        <img
          src={item.photo}
          alt='review'
          style={{
            width: 50,
            height: 50,
            marginBottom: 10,
            objectFit: 'cover',
            borderRadius: '10px 0 10px 0',
          }}
        />
        <text.T10 style={{color: theme.colors.textColor}}>{item.date}</text.T10>
        <div style={{...utils.rowCenter()}}>
          <svg.ReviewStarSvg />
          <text.T10 style={{marginLeft: 4, color: theme.colors.textColor}}>
            {item.rating.toFixed(1)}
          </text.T10>
        </div>
      </div>
      {/* Right Block */}
      <div style={{padding: ' 19px 20px 20px 0'}}>
        <div style={{marginBottom: 16, ...utils.rowCenterSpcBtween()}}>
          <text.H5
            style={{textTransform: 'capitalize', color: theme.colors.mainColor}}
          >
            {item.name}
          </text.H5>
          <button>
            <text.T12 style={{color: theme.colors.mainColor}}>reply</text.T12>
          </button>
        </div>
        <text.T16 style={{color: theme.colors.textColor}}>
          {item.comment}
        </text.T16>
      </div>
    </div>
  );
};
