import React, {useState} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {theme} from '../constants';
import {components} from '../components';

export const LeaveAReview: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const [rating, setRating] = useState<number>(0);

  const renderHeader = () => {
    return <components.Header title='Leave a review' goBack={true} />;
  };

  const renderContent = () => {
    return (
      <main
        style={{
          padding: '0px 20px 20px 20px',
          paddingTop: '10vh',
          marginTop: 52,
        }}
      >
        <img
          alt='order successful'
          src={require('../assets/icons/21.png')}
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            color: theme.colors.mainColor,
            marginBottom: 14,
            textTransform: 'capitalize',
            whiteSpace: 'pre-line',
          }}
        >
          Please rate the quality of{'\n'}service for the order!
        </text.H2>
        <components.RatingStars
          containerStyle={{marginBottom: 20}}
          setRating={setRating}
          rating={rating}
        />
        <text.T16
          style={{
            color: theme.colors.textColor,
            lineHeight: 1.7,
            marginBottom: 50,
          }}
        >
          Your comments and suggestions help us improve the service quality
          better!
        </text.T16>
        <div
          style={{
            position: 'relative',
            border: '1px solid #E7EBEB',
            marginBottom: 20,
            borderRadius: 10,
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: -8,
              left: 20,
              padding: '0 10px',
              borderRadius: 12,
              backgroundColor: '#fff',
              ...theme.fonts.DM_Sans_500Medium,
              fontSize: 12,
              textTransform: 'uppercase',
              color: theme.colors.textColor,
            }}
          >
            comment
          </span>
          <textarea
            className='input-field'
            style={{
              width: '100%',
              height: 130,
              borderColor: '#E8EFF4',
              marginBottom: 40,
              borderRadius: 12,
              display: 'block',
              boxSizing: 'border-box',
              verticalAlign: 'top',
              overflow: 'auto',
              textAlign: 'left',
              padding: '14px 30px',
              resize: 'none',
              color: theme.colors.mainColor,
            }}
            placeholder='Enter your comment'
          />
        </div>

        <components.Button
          title='submit'
          onClick={() => {
            navigate(-1);
          }}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
