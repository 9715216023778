import React from 'react';

import {text} from '../text';
import {utils} from '../utils';
import {svg} from '../assets/svg';
import {theme} from '../constants';

type Props = {
  title: string;
  viewAllVisible?: boolean;
  viewAllOnClick?: () => void;
  containerStyle?: React.CSSProperties;
};

export const BlockHeading: React.FC<Props> = ({
  title,
  viewAllOnClick,
  containerStyle,
  viewAllVisible = true,
}) => {
  return (
    <div style={{...utils.rowCenterSpcBtween(), ...containerStyle}}>
      <text.H3 style={{color: theme.colors.mainColor}}>{title}</text.H3>
      {viewAllVisible && (
        <button onClick={viewAllOnClick} style={{lineHeight: 0}}>
          <svg.ViewAllSvg />
        </button>
      )}
    </div>
  );
};
