import React from 'react';
import {theme} from '../constants';

import {text} from '../text';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {product} from '../product';

type Props = {
  item: any;
  isLast?: boolean;
  version?: number;
};

export const CaregoryProductCard: React.FC<Props> = ({
  isLast,
  item,
  version = 1,
}) => {
  const navigate = hooks.useAppNavigate();

  // Home > BestSeller item //
  if (version === 1) {
    return (
      <div
        style={{
          width: 330,
          borderRadius: 12,
          cursor: 'pointer',
          marginRight: isLast ? 20 : 14,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          padding: 5,
        }}
        onClick={() => navigate('/product', {state: {item}})}
      >
        <custom.ImageBackground
          imageUrl={item.attachments.product_main}
          style={{
            width: 250,
            height: 250,
            borderRadius: 10,
            marginBottom: 14,
            position: 'relative',
          }}
        >
          <product.ProductSales
            item={item}
            style={{position: 'absolute', padding: 14, left: 0, top: 0}}
          />
          <product.ProductInWishlist
            item={item}
            style={{position: 'absolute', padding: 14, right: 0, top: 0}}
          />
          <product.ProductInCart
            item={item}
            style={{position: 'absolute', right: 0, bottom: 0, padding: 14}}
          />
        </custom.ImageBackground>
        {item.default_sales_type === 'PACK' && (
          <div
            style={{
              fontSize: 12,
              ...theme.fonts.DM_Sans_400Regular,
              color: theme.colors.steelTeal,
              // display: 'flex',
              // justifyContent: 'space-between',
            }}
          >
            {' '}
            <div>{`Minimum Order: ${item.unit_per_pack} Items`}</div>
            {`£${item.price} per piece * ${item.unit_per_pack} Items`}
          </div>
        )}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <product.ProductName
              item={item.length > 20 ? item.substring(0, 15) + '...' : item}
              style={{
                marginBottom: 2,
                marginRight: 2,
                whiteSpace: 'nowrap', // Prevent wrapping
                overflow: 'hidden', // Hide overflowed text
                textOverflow: 'ellipsis', // Add ellipsis for overflowing text
                maxWidth: '200px', // Set a max-width for the text (adjust as needed)
              }}
            />
          </div>
          <div>
            {/* Adjust character limit */}
            <product.ProductPrice item={item} />
          </div>
        </div>
      </div>
    );
  }

  // Shop Item
  if (version === 2) {
    return (
      <div
        style={{
          width: `calc(50% - 7.5px)`,
          cursor: 'pointer',
          borderRadius: 12,
          marginBottom: 20,
        }}
        onClick={() => {
          navigate('/product', {state: {item}});
        }}
      >
        <custom.ImageBackground
          imageUrl={item.image}
          style={{
            width: '100%',
            height: 170,
            borderRadius: 10,
            marginBottom: 6,
            position: 'relative',
            backgroundSize: 'contain',
            backgroundColor: theme.colors.imageBackground,
          }}
        >
          <product.ProductInWishlist
            item={item}
            style={{position: 'absolute', padding: 10, right: 0, top: 0}}
          />
          <product.ProductInCart
            item={item}
            style={{position: 'absolute', right: 0, bottom: 0, padding: 10}}
          />
        </custom.ImageBackground>
        <product.ProductName item={item} style={{marginBottom: 3}} />
        <product.ProductPrice item={item} />
      </div>
    );
  }

  // Featured Item
  if (version === 3) {
    const blockWidth = 138;

    return (
      <div
        style={{
          width: blockWidth,
          cursor: 'pointer',
          marginRight: isLast ? 20 : 14,
          borderRadius: 12,
        }}
        onClick={() => {
          navigate('/product', {state: {item}});
        }}
      >
        <custom.ImageBackground
          imageUrl={item.image}
          style={{
            width: blockWidth,
            height: 170,
            borderRadius: 10,
            marginBottom: 6,
            position: 'relative',
            backgroundSize: 'contain',
            backgroundColor: theme.colors.imageBackground,
          }}
        >
          <product.ProductInWishlist
            item={item}
            style={{position: 'absolute', padding: 10, right: 0, top: 0}}
          />
          <product.ProductInCart
            item={item}
            style={{position: 'absolute', padding: 10, right: 0, bottom: 0}}
          />
        </custom.ImageBackground>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <product.ProductName
            item={item}
            style={{
              marginBottom: 2,
              marginRight: 2,
              flex: 1, // Allow it to take available space
              whiteSpace: 'nowrap', // Prevent text from wrapping
              overflow: 'hidden', // Hide overflowed text
              textOverflow: 'ellipsis', // Add ellipsis for overflowing text
            }}
          />
          <div
            style={{marginLeft: '8px', flexShrink: 0}} // Prevent shrinking of price
          >
            <product.ProductPrice item={item} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};
