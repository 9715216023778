import * as React from 'react';

import {theme} from '../../constants';

type Props = {
  bgColor?: string;
  iconColor?: string;
  onClick?: () => void;
};

export const CategoryTabSvg: React.FC<Props> = ({
  onClick,
  bgColor = `${theme.colors.black}20`,
  iconColor = theme.colors.pastelMint,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={50}
      height={50}
      fill='none'
      onClick={onClick}
    >
      <circle cx={25} cy={25} r={25} fill={bgColor} />
      <path
        fill={iconColor}
        fillRule='evenodd'
        d='M24 16.75a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5ZM15.25 24a8.75 8.75 0 1 1 17.5 0 8.75 8.75 0 0 1-17.5 0Z'
        clipRule='evenodd'
      />
      <path
        fill={iconColor}
        fillRule='evenodd'
        d='M28.943 28.943a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  );
};
