// items/NotificationItem.tsx
import React from 'react';

type NotificationItemProps = {
  notification: any; // Adjust type based on your actual notification data structure
  isLast: boolean;
};

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  isLast,
}) => {
  const {data, created_at} = notification;
  const {event, message} = data.meta;

  return (
    <div
      style={{
        padding: '15px',
        borderBottom: isLast ? 'none' : '1px solid #e0e0e0',
        backgroundColor: notification.read_at ? '#f9f9f9' : '#ffffff',
      }}
    >
      <h3 style={{margin: 0, fontSize: '16px', fontWeight: 600}}>{event}</h3>
      <p style={{margin: '5px 0', fontSize: '14px', color: '#555'}}>
        {message}
      </p>
      <p style={{margin: 0, fontSize: '12px', color: '#999'}}>
        {new Date(created_at).toLocaleDateString()}{' '}
        {new Date(created_at).toLocaleTimeString()}
      </p>
    </div>
  );
};
