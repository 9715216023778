import axios from 'axios';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import {text} from '../text';
import {utils} from '../utils';
import {items} from '../items';
import {URLS} from '../config';
import {hooks} from '../hooks';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {product} from '../product';
import {actions} from '../store/actions';
import {components} from '../components';
import {custom} from '../custom';
import {ProductType} from '../types';
import {fetchRelatedProducts} from '../services/categoryService';

export const Product: React.FC = () => {
  const location = useLocation();
  const item = location.state.item;
  console.log(item);
  const navigate = useNavigate();
  const dispatch = hooks.useAppDispatch();

  const [emblaRef, emblaApi] = useEmblaCarousel();

  const handleSlideChange = () => {
    if (emblaApi) {
      setActiveIndex(emblaApi.selectedScrollSnap());
    }
  };

  const [productsData, setProductsData] = useState<ProductType[]>([]);
  const [relatedProducts, setRelatedProducts] = useState<ProductType[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Fetch related products when the component mounts

    window.scroll(0, 0);
    const fetchProducts = async () => {
      try {
        const response = await fetchRelatedProducts(item.id);
        setRelatedProducts(response);
      } catch (error) {
        console.error('Error fetching related products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [item.id]);

  console.log(relatedProducts);
  useEffect(() => {
    updateProductImages();
    if (emblaApi) {
      emblaApi.on('select', handleSlideChange);
    }
  }, [emblaApi]);
  const updateProductImages = () => {
    const updatedProducts = productsData.map((product) => {
      return {
        ...product,
        image:
          'https://img.freepik.com/premium-photo/3d-render-photos-healthy-food-meal_1096167-99597.jpg?w=1380', // Update the image URL as needed
      };
    });

    setProductsData(updatedProducts);
  };
  const getData = async () => {
    setLoading(true);
    console.log(productsData);

    try {
      const products = await axios
        .get(URLS.GET_PRODUCTS)
        .then((res) => res.data.products);

      setProductsData(products);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getData();
  //   // window.scrollTo(0, 0);
  // });

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', handleSlideChange);
    }
  }, [emblaApi]);

  const [reviewsData, setReviewsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedColor, setSelectedColor] = useState<string>(
    item.colors?.[1].name
  );

  const modifiedItem = {
    ...item,
    color: selectedColor,
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const renderHeader = (): JSX.Element => {
    return (
      <components.Header
        goBack={false}
        navlink={true}
        line={true}
        basket={true}
      />
    );
  };

  const renderRelatedProducts = (): JSX.Element => {
    if (loading) return <components.TabLoader />;
    console.log(relatedProducts);
    const categoryId =
      relatedProducts.length > 0 ? relatedProducts[0].category_id : 7; // Default to 7 if no related products

    return (
      <div style={{marginBottom: 40, display: 'flex', flexDirection: 'column'}}>
        <components.BlockHeading
          title='Related Products'
          containerStyle={{padding: '0 20px 0', marginBottom: 14}}
          // viewAllOnClick={() => {
          //   navigate('/Shop', {
          //     state: {products: relatedProducts, title: 'Related Products'},
          //   });
          // }}
          viewAllOnClick={() => {
            dispatch(actions.resetFilters());
            navigate('/ShopHome', {
              state: {CategoryId: categoryId},
            });
          }}
        />
        <custom.ScrollView style={{paddingLeft: 20, paddingRight: 20}}>
          {relatedProducts.map(
            (relatedItem: ProductType, index: number, arr: ProductType[]) => {
              const isLast = index === arr.length - 1;
              return (
                <items.CaregoryProductCard
                  key={relatedItem.id}
                  isLast={isLast}
                  item={relatedItem}
                  version={1}
                />
              );
            }
          )}
        </custom.ScrollView>
      </div>
    );
  };

  const renderIndicator = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 30,
          alignSelf: 'center',
          flexDirection: 'row',
          width: '100%',
          gap: 6,
        }}
      >
        {item.images?.map((item: any, index: number) => {
          const isSelected = index === activeIndex;
          return (
            <div
              style={{
                background: isSelected
                  ? theme.colors.mainColor
                  : theme.colors.white,
                width: 10,
                height: 10,
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isSelected ? theme.colors.mainColor : '#E7EBEB',
              }}
              key={index}
            />
          );
        })}
      </div>
    );
  };

  const renderCarouselContent = (): JSX.Element => {
    return (
      <div className='embla' ref={emblaRef}>
        <div className='embla__container'>
          <div
            style={{
              height: 500,
              width: '100%',
              cursor: 'pointer',
              backgroundColor: theme.colors.imageBackground,
            }}
            className='embla__slide'
          >
            <img
              src={item.attachments.product_main}
              alt='Carousel'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCarousel = (): JSX.Element => {
    return (
      <section style={{position: 'relative', marginBottom: 30}}>
        {renderCarouselContent()}
        {renderIndicator()}
      </section>
    );
  };

  const renderNameWithButton = (): JSX.Element => {
    return (
      <div
        style={{
          marginBottom: 30,
          padding: '0 20px 0 20px',
          ...utils.rowCenterSpcBtween(),
        }}
      >
        <text.H3 style={{color: theme.colors.mainColor}}>{item.name}</text.H3>
      </div>
    );
  };

  const renderPriceWithQuantity = (): JSX.Element => {
    return (
      <div style={{padding: '10px 4px 10px 20px', margin: '0 0 30px 20px'}}>
        <div
          style={{
            padding: '10px 4px 10px 20px',
            margin: '0 0 30px 20px',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            ...utils.rowCenterSpcBtween(),
            border: `1px solid ${theme.colors.antiFlashWhite}`,
            borderRight: '0px',
          }}
        >
          <div
            style={{
              fontSize: 20,
              ...theme.fonts.DM_Sans_700Bold,
              color: theme.colors.mainColor,
            }}
          >
            {item.default_sales_type === 'PACK' && (
              <div
                style={{
                  fontSize: 12,
                  ...theme.fonts.DM_Sans_400Regular,
                  color: theme.colors.steelTeal,
                  // display: 'flex',
                  // justifyContent: 'space-between',
                }}
              >
                {' '}
                <div>{`Minimum Order is 1 Pack = ${item.unit_per_pack} Items`}</div>
                {`£${item.price} per piece * ${item.unit_per_pack} Items`}
              </div>
            )}
            £
            {item.default_sales_type === 'PACK'
              ? `${item.price_per_pack} per Pack`
              : item.price}
          </div>

          <product.ProductCounterInner item={modifiedItem} />
        </div>
        {/* <div
          style={{
            marginTop: 4,
            fontSize: 14,
            ...theme.fonts.DM_Sans_400Regular,
            color: theme.colors.mainColor,
          }}
        >

          {item.price_per_pack && `Price per pack: £${item.price_per_pack}`}
        </div> */}
        {/* <div
          style={{
            fontSize: 14,
            ...theme.fonts.DM_Sans_400Regular,
            color: theme.colors.mainColor,
          }}
        >
          {item.unit_per_pack && `Units per pack: ${item.unit_per_pack} Units`}
        </div> */}
        {/* <div
          style={{
            fontSize: 14,
            ...theme.fonts.DM_Sans_400Regular,
            color: theme.colors.mainColor,
          }}
        >
          {item.unit_per_pack &&
            item.weight &&
            `Total weight per pack: ${(
              item.weight * item.unit_per_pack
            ).toFixed(2)} kg`}
        </div> */}
      </div>
    );
  };

  const renderColors = (): JSX.Element => {
    return (
      <div style={{marginBottom: 40, padding: '0 20px 0 20px'}}>
        <text.H5 style={{marginBottom: 20, color: theme.colors.mainColor}}>
          Color
        </text.H5>
        <div
          style={{
            gap: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {item.colors?.map((item: any, index: number) => {
            return (
              <button
                key={index}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: item.code,
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  border:
                    item.name === 'White'
                      ? '#E7EBEB'
                      : theme.colors.transparent,
                  borderStyle: 'solid',
                  borderWidth: 1,
                }}
                onClick={() => {
                  setSelectedColor(item.name);
                }}
              >
                {selectedColor === item.name && (
                  <svg.CheckSvg
                    color={
                      item.name === 'White'
                        ? theme.colors.mainColor
                        : theme.colors.white
                    }
                  />
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDescription = (): JSX.Element => {
    console.log(item);
    return (
      <div style={{marginBottom: 40, padding: '0 20px 0 20px'}}>
        <text.H5 style={{marginBottom: 20, color: theme.colors.mainColor}}>
          Description
        </text.H5>
        <text.T16 style={{marginBottom: 20, color: theme.colors.textColor}}>
          {item.description}
        </text.T16>
        {/* <button
          onClick={() => {
            navigate('/description', {state: {item}});
          }}
        >
          <svg.ReadMoreSvg />
        </button> */}
      </div>
    );
  };

  const renderReviews = (): JSX.Element => {
    const reviewQty = 2;
    return (
      <div style={{marginBottom: 20}}>
        <components.BlockHeading
          title={`Reviews (${reviewsData.slice(0, reviewQty).length})`}
          containerStyle={{padding: '0 20px 0 20px', marginBottom: 20}}
          viewAllOnClick={() =>
            navigate('/reviews', {state: {reviews: reviewsData}})
          }
        />
        {reviewsData
          .slice(0, reviewQty)
          .map((item: any, index: number, array: any) => {
            const isLast = index === array.length - 1;
            return (
              <items.ReviewItem
                item={item}
                isLast={isLast}
                key={item.id.toString()}
              />
            );
          })}
      </div>
    );
  };

  const renderButtons = (): JSX.Element => {
    const isInCart = hooks
      .useAppSelector((state: any) => state.cartSlice.list)
      .some((i: any) => i.id === item.id);

    const isSignedIn = Boolean(sessionStorage.getItem('authToken')); // Or use a state from Redux

    const handleAddToCart = () => {
      if (!isSignedIn) {
        // Redirect to the sign-in page if not signed in
        navigate('/SignIn');
      } else {
        dispatch(actions.addToCart(modifiedItem));
      }
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          padding: '0 20px',
          maxWidth: '350px',
        }}
      >
        {isInCart ? (
          <div style={{marginTop: '10px'}}>
            <components.Button
              title='Remove from cart'
              onClick={() => {
                dispatch(actions.removeFromCart(modifiedItem));
              }}
              colorScheme='light'
            />
          </div>
        ) : (
          <div style={{marginTop: '10px'}}>
            {' '}
            <components.Button title='Add to cart' onClick={handleAddToCart} />
          </div>
        )}
      </div>
    );
  };

  const renderFooter = (): JSX.Element => {
    if (loading) return <components.TabLoader />;

    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };
  const renderContent = (): JSX.Element => {
    if (isLoading) return <components.Loader />;

    return (
      <main style={{padding: '0 0 20px 0', marginTop: 52}}>
        <div style={{display: 'flex', marginTop: 180}}>
          {/* Carousel with fixed width */}
          <div style={{flex: '1 1 50%', maxWidth: '50%'}}>
            {renderCarousel()}
          </div>

          {/* Content section with fixed width */}
          <div style={{flex: '1 1 50%', maxWidth: '50%', paddingLeft: 20}}>
            {renderNameWithButton()}
            {renderPriceWithQuantity()}

            {/* Scrollable description if too long */}
            <div>
              {renderDescription()} {renderButtons()}
            </div>
          </div>
        </div>
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderRelatedProducts()}
      {renderFooter()}
    </>
  );
};
