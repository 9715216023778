import React, {useEffect} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {theme} from '../constants';
import {components} from '../components';

export const SignOut: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{
          padding: '50px 20px 20px 20px',
          height: 'calc(100vh - 80px - 20px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          src={require('../assets/icons/23.png')}
          alt='order successful'
          style={{width: 123.39, marginBottom: 4}}
        />
        <text.H2
          style={{
            marginBottom: 30,
            textTransform: 'capitalize',
            color: theme.colors.mainColor,
            whiteSpace: 'pre-line',
          }}
        >
          Are you sure you{'\n'}want to sign out ?
        </text.H2>
        <text.T16 style={{marginBottom: 30, color: theme.colors.textColor}}>
          Something went wrong. Please try again to contimue your order.
        </text.T16>
        <components.Button
          title='Cancel'
          onClick={() => {
            navigate(-1);
          }}
          containerStyle={{marginBottom: 10}}
        />
        <components.Button
          colorScheme='light'
          title='Sure'
          onClick={() => {
            sessionStorage.removeItem('authToken');
            localStorage.removeItem('profile');

            navigate('/SignIn');
          }}
        />
      </main>
    );
  };

  return <>{renderContent()}</>;
};
