import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useThemeColor from '../hooks/useThemeColor';

import {text} from '../text';
import {utils} from '../utils';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {signIn} from '../services/authService';
import {CircularProgress} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
export const SignIn: React.FC = () => {
  const navigate = useNavigate();

  const [themeColor, setThemeColor] = useState('#fff');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setIsLoading] = React.useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      const response = await signIn(email, password);
      // Handle successful sign-in, e.g., save tokens, navigate to a different page
      console.log(response);
      navigate('/home');
    } catch (error) {
      setError('Credentials are not correct.');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setThemeColor('white');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useThemeColor(themeColor);

  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const renderHeader = () => <components.Header />;

  const renderContent = (): JSX.Element => {
    const socialStyle: React.CSSProperties = {
      width: '30%',
      height: 40,
      ...utils.flexCenter(),
      borderRadius: '10px',
      border: '1px solid #E8EFF4',
    };

    return (
      <main
        className='container'
        style={{
          padding: '50px 20px 20px 20px',
          marginTop: 10,
          textAlign: 'center', // Center align all content within the main container
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <img
            src='https://mariaandsons.com/img/Icons/m&s_lgo.png'
            alt='Logo'
            style={{
              width: '270px',
              height: '150px',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/SignIn')}
          />
        </div>

        <text.H1 style={{marginBottom: 14}}>Welcome Back!</text.H1>
        <text.T16 style={{marginBottom: 40}}>Sign in to continue</text.T16>

        <div>
          <custom.InputField
            label='Email'
            icon={<svg.InputCheckSvg />}
            containerStyle={{marginBottom: 20}}
            placeholder='Your Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <custom.InputField
            label='Password'
            placeholder='Enter Password'
            icon={
              <div
                onClick={togglePasswordVisibility}
                style={{cursor: 'pointer'}}
              >
                {isPasswordVisible ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <svg.EyeOffSvg />
                )}
              </div>
            }
            containerStyle={{marginBottom: 20}}
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div style={{...utils.rowCenterSpcBtween(), marginBottom: 30}}>
          <div
            style={{cursor: 'pointer', ...utils.rowCenterSpcBtween()}}
            onClick={() => setRememberMe(!rememberMe)}
          >
            {/* <div
              style={{
                width: 18,
                height: 18,
                borderRadius: 5,
                border: '2px solid #E8EFF4',
                ...utils.flexCenter(),
              }}
            >
              {rememberMe && <svg.InputCheckSvg />}
            </div> */}
            {/* <text.T14 style={{marginLeft: 12}}>Remember me</text.T14> */}
          </div>
          {/* <text.T16
            style={{cursor: 'pointer'}}
            onClick={() => navigate('/ForgotPassword')}
          >
            Forgot password?
          </text.T16> */}
        </div>
        {error && <div style={{color: 'red'}}>{error}</div>}
        {loading ? (
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <components.Button
            title='Sign in'
            onClick={handleSignIn}
            style={{marginBottom: 20}}
          />
        )}

        <div style={{...utils.rowCenter(), marginBottom: 50}}>
          <text.T16 style={{marginRight: 4}}>Don’t have an account?</text.T16>
          <text.T16
            onClick={() => navigate('/SignUp')}
            style={{cursor: 'pointer', color: theme.colors.mainColor}}
          >
            Sign up.
          </text.T16>
        </div>
      </main>
    );
  };

  return (
    <>
      {/* {renderHeader()} */}
      {renderContent()}
    </>
  );
};
