import {FC} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';

export const NewPassword: FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderHeader = () => {
    return <components.Header title='Reset password' goBack={true} />;
  };

  const renderContent = () => {
    return (
      <main style={{padding: '30px 20px 20px 20px', marginTop: 52}}>
        <text.T16
          style={{
            marginBottom: 40,
            color: theme.colors.textColor,
          }}
        >
          Enter new password and confirm.
        </text.T16>
        <custom.InputField
          clickable={true}
          label='password'
          placeholder='••••••••'
          type='password'
          icon={<svg.EyeOffSvg />}
          containerStyle={{marginBottom: 20}}
        />
        <custom.InputField
          clickable={true}
          type='password'
          label='confirm password'
          placeholder='••••••••'
          icon={<svg.EyeOffSvg />}
          containerStyle={{marginBottom: 20}}
        />
        <components.Button
          title='change password'
          onClick={() => {
            navigate('/ForgotPasswordSentEmail');
          }}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
