import * as React from 'react';

type Props = {iconColor: string};

const HomeTabSvg: React.FC<Props> = ({iconColor = '#666666'}) => {
  return (
    <svg width={24} height={24} fill='none'>
      <g>
        <path
          fill='#F5C102'
          d='m23.4 10.392-.002-.002L13.608.6a2.194 2.194 0 0 0-1.562-.647c-.59 0-1.145.23-1.563.647L.698 10.385a2.212 2.212 0 0 0-.006 3.13 2.197 2.197 0 0 0 1.535.648h.39v7.204a2.589 2.589 0 0 0 2.586 2.586h3.83a.703.703 0 0 0 .703-.703v-5.648c0-.651.53-1.18 1.18-1.18h2.26c.65 0 1.18.529 1.18 1.18v5.648c0 .388.314.703.702.703h3.83a2.589 2.589 0 0 0 2.586-2.586v-7.204h.362c.59 0 1.145-.23 1.563-.648.86-.86.86-2.261.001-3.123Zm-.996 2.13a.798.798 0 0 1-.568.235h-1.065a.703.703 0 0 0-.703.703v7.907c0 .65-.529 1.18-1.18 1.18h-3.127v-4.945a2.589 2.589 0 0 0-2.586-2.586h-2.259a2.59 2.59 0 0 0-2.586 2.586v4.945H5.203c-.65 0-1.18-.53-1.18-1.18V13.46a.703.703 0 0 0-.703-.703H2.273a.797.797 0 0 1-.586-.236.804.804 0 0 1 0-1.136h.001l9.79-9.79a.797.797 0 0 1 .568-.236c.214 0 .416.084.568.236l9.787 9.787a.805.805 0 0 1 .003 1.14Z'
        />
      </g>
      <defs>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </defs>
    </svg>
  );
};

const CategoryTabSvg: React.FC<Props> = ({iconColor = '#666666'}) => {
  return (
    <svg width={28} height={28} fill='none'>
      <path
        fill={iconColor}
        fillRule='evenodd'
        d='M12.833 4.375a8.458 8.458 0 1 0 0 16.917 8.458 8.458 0 0 0 0-16.917ZM2.625 12.833c0-5.638 4.57-10.208 10.208-10.208s10.209 4.57 10.209 10.208-4.57 10.209-10.209 10.209c-5.638 0-10.208-4.57-10.208-10.209Z'
        clipRule='evenodd'
      />
      <path
        fill={iconColor}
        fillRule='evenodd'
        d='M18.6 18.6a1.167 1.167 0 0 1 1.65 0l5.075 5.075a1.167 1.167 0 0 1-1.65 1.65L18.6 20.25a1.167 1.167 0 0 1 0-1.65Z'
        clipRule='evenodd'
      />
    </svg>
  );
};

const MyCoursesTabSvg: React.FC<Props> = ({iconColor = '#666666'}) => {
  return (
    <svg width={28} height={28} fill='none'>
      <path
        stroke={iconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M2.333 3.5h7A4.666 4.666 0 0 1 14 8.167V24.5a3.5 3.5 0 0 0-3.5-3.5H2.333V3.5Z'
      />
      <path
        stroke={iconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M25.667 3.5h-7A4.667 4.667 0 0 0 14 8.167V24.5a3.5 3.5 0 0 1 3.5-3.5h8.167V3.5Z'
      />
    </svg>
  );
};

const ProfileTabSvg: React.FC<Props> = ({iconColor = '#666666'}) => {
  return (
    <svg width={28} height={28} fill='none'>
      <g>
        <path
          fill={iconColor}
          d='M23.9 4.1A13.908 13.908 0 0 0 14 0c-3.74 0-7.255 1.456-9.9 4.1A13.908 13.908 0 0 0 0 14c0 3.74 1.456 7.255 4.1 9.9A13.908 13.908 0 0 0 14 28c3.74 0 7.255-1.456 9.9-4.1A13.909 13.909 0 0 0 28 14c0-3.74-1.456-7.255-4.1-9.9ZM7.018 24.193A7.069 7.069 0 0 1 14 18.335a7.068 7.068 0 0 1 6.981 5.858A12.289 12.289 0 0 1 14 26.359c-2.589 0-4.994-.8-6.981-2.166Zm2.53-11.95A4.456 4.456 0 0 1 14 7.79a4.457 4.457 0 0 1 4.451 4.452A4.457 4.457 0 0 1 14 16.694a4.457 4.457 0 0 1-4.451-4.451Zm12.853 10.812a8.72 8.72 0 0 0-2.547-4.106 8.72 8.72 0 0 0-2.535-1.6 6.091 6.091 0 0 0 2.772-5.106A6.1 6.1 0 0 0 14 6.15a6.099 6.099 0 0 0-6.092 6.092 6.092 6.092 0 0 0 2.772 5.106 8.722 8.722 0 0 0-2.535 1.6 8.722 8.722 0 0 0-2.547 4.106A12.328 12.328 0 0 1 1.64 14C1.64 7.185 7.185 1.64 14 1.64S26.36 7.186 26.36 14c0 3.573-1.526 6.796-3.958 9.055Z'
        />
      </g>
      <defs>
        <path fill='#fff' d='M0 0h28v28H0z' />
      </defs>
    </svg>
  );
};

const OrderTabSvg: React.FC<Props> = ({iconColor = '#666666'}) => {
  return (
    <svg width={24} height={24} fill='none'>
      <path
        fill={iconColor}
        d='M20.774 20.729 19.498 6.683A.752.752 0 0 0 18.75 6H16.5V4.5a4.475 4.475 0 0 0-1.315-3.186A4.475 4.475 0 0 0 12 0a4.506 4.506 0 0 0-4.5 4.5V6H5.25a.749.749 0 0 0-.747.683L3.226 20.729a3.007 3.007 0 0 0 .772 2.294A3.005 3.005 0 0 0 6.214 24h11.572c.842 0 1.649-.355 2.216-.977a3.013 3.013 0 0 0 .773-2.294ZM9 4.5c0-1.655 1.346-3 3-3a2.988 2.988 0 0 1 3 3V6H9V4.5Zm9.894 17.512a1.489 1.489 0 0 1-1.108.488H6.214c-.426 0-.82-.174-1.107-.489a1.48 1.48 0 0 1-.386-1.148L5.935 7.5h1.564v2.25a.75.75 0 0 0 1.5 0V7.5h6v2.25a.75.75 0 0 0 1.5 0V7.5h1.565l1.215 13.365c.039.425-.098.833-.386 1.148Z'
      />
    </svg>
  );
};

const getTabs = () => {
  return [
    {
      id: 1,
      name: 'Home',
      icon: HomeTabSvg,
    },
    {
      id: 2,
      name: 'Search',
      icon: CategoryTabSvg,
    },
    {
      id: 3,
      name: 'Order',
      icon: OrderTabSvg,
    },
    {
      id: 4,
      name: 'Wishlist',
      icon: MyCoursesTabSvg,
    },
    {
      id: 5,
      name: 'Profile',
      icon: ProfileTabSvg,
    },
  ];
};

export default getTabs;
