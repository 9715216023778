import * as React from 'react';

const ReviewStarSvg: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={8} height={8} fill='none'>
      <g clipPath='url(#a)'>
        <path
          fill='#4A6973'
          stroke='#4A6973'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='m4 .667 1.03 2.086 2.303.337-1.666 1.623.393 2.294L4 5.923 1.94 7.007l.393-2.294L.667 3.09l2.303-.337L4 .667Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h8v8H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ReviewStarSvg;
