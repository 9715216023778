import React, {useEffect} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {theme} from '../constants';
import {components} from '../components';
import {actions} from '../store/actions';

export const OrderSuccessful: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();

  useEffect(() => {
    dispatch(actions.resetCart());
    window.scrollTo(0, 0);
  }, []);

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='full-height'
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <img
          alt='order successful'
          src={require('../assets/icons/26.png')}
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            marginBottom: 14,
            color: theme.colors.mainColor,
            textTransform: 'capitalize',
          }}
        >
          Thank you for your order!
        </text.H2>
        <text.T16
          style={{
            color: theme.colors.textColor,
            whiteSpace: 'pre-line',
            marginBottom: 30,
          }}
        >
          Your order will be delivered on time.{'\n'}Thank you!
        </text.T16>
        <components.Button
          title='View orders'
          onClick={() => {
            dispatch(actions.setScreen('Home'));
            navigate('/OrderHistory');
          }}
          containerStyle={{marginBottom: 10, width: '100%'}}
        />
        <components.Button
          title='Continue Shopping'
          colorScheme='light'
          onClick={() => {
            navigate('/TabNavigator');
          }}
          containerStyle={{width: '100%'}}
        />
      </main>
    );
  };

  return renderContent();
};
