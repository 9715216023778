import {FC, useState} from 'react';
import {CSSProperties} from 'react';

import {utils} from '../utils';
import {theme} from '../constants';

type Props = {
  hover?: boolean;
  numberOfLines?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const T18: FC<Props> = ({
  children,
  hover = false,
  style = {},
  numberOfLines = 0,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const baseStyles: CSSProperties = {
    fontSize: 18,
    lineHeight: 1.6,
    // ...theme.fonts.Spartan_300Light,
    // ...(hover ? utils.hover(isHovered) : {}),
    // ...(numberOfLines
    //   ? (utils.numberOfLines(numberOfLines) as CSSProperties)
    //   : {}),
  };

  return (
    <p
      style={{...baseStyles, ...style}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </p>
  );
};

export default T18;
