import * as React from 'react';

export const WishlistBagSvg: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={33} height={33} fill='none'>
      <rect width={33} height={33} fill='#193364' rx={16.5} />
      <path
        fill='#fff'
        d='m22.715 22.683-.904-9.95a.532.532 0 0 0-.53-.483h-1.594v-1.063a3.17 3.17 0 0 0-.93-2.256 3.191 3.191 0 0 0-5.444 2.257v1.062h-1.595a.53.53 0 0 0-.529.483l-.904 9.95a2.13 2.13 0 0 0 .547 1.625c.402.44.973.692 1.57.692h8.197c.596 0 1.168-.252 1.57-.692.4-.44.6-1.032.546-1.625Zm-8.34-11.495a2.127 2.127 0 0 1 3.63-1.506c.4.4.62.935.62 1.505v1.063h-4.25v-1.063Zm7.008 12.404a1.055 1.055 0 0 1-.785.346h-8.196a1.05 1.05 0 0 1-.784-.347 1.048 1.048 0 0 1-.274-.813l.86-9.465h1.108v1.593a.531.531 0 0 0 1.063 0v-1.594h4.25v1.594a.531.531 0 0 0 1.062 0v-1.594h1.108l.861 9.467c.028.301-.07.59-.273.813Z'
      />
      <path stroke='#fff' strokeLinecap='round' d='M16.5 17.5v4M14.5 19.5h4' />
    </svg>
  );
};
