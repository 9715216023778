import axios from 'axios';
import React, {useState, useEffect} from 'react';

import {text} from '../text';
import {utils} from '../utils';
import {hooks} from '../hooks';
import {URLS} from '../config';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {RootState} from '../store';
import {actions} from '../store/actions';
import {components} from '../components';

const labels = ['sale', 'top', 'new'];
const rating = [
  {
    id: 1,
    rating: 1,
    title: '1,0',
  },
  {
    id: 2,
    rating: 2,
    title: '2,0',
  },
  {
    id: 3,
    rating: 3,
    title: '3,0',
  },
  {
    id: 4,
    rating: 4,
    title: '4,0',
  },
  {
    id: 5,
    rating: 5,
    title: '5,0',
  },
];

export const Filter: React.FC = () => {
  const navigate = hooks.useAppNavigate();
  const dispatch = hooks.useAppDispatch();

  const [colors, setColors] = useState<string[]>([]);
  const [potTypes, setPotTypes] = useState<string[]>([]);
  const [plantTypes, setPlantTypes] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    selectedColors,
    selectedRatings,
    selectedPotTypes,
    selectedCategories,
    selectedPlantTypes,
  } = hooks.useAppSelector((state: RootState) => state.filterSlice);

  const getData = async () => {
    setIsLoading(true);

    try {
      const products = await axios
        .get(URLS.GET_PRODUCTS)
        .then((res) => res.data.products);

      let uniqueColors: string[] = [];
      let uniquePotTypes: string[] = [];
      let uniquePlantTypes: string[] = [];

      products.forEach((product: any) => {
        product.colors.forEach((color: any) => {
          if (
            !uniqueColors.some(
              (uniqueColor: any) => uniqueColor.id === color.id
            )
          ) {
            uniqueColors.push(color);
          }
        });
      });

      products.forEach((product: any) => {
        uniquePotTypes = uniquePotTypes.concat(product.potTypes);
      });

      uniquePotTypes = uniquePotTypes.filter(
        (size: any, index: any) => uniquePotTypes.indexOf(size) === index
      );

      products.forEach((product: any) => {
        uniquePlantTypes = uniquePlantTypes.concat(product.plantTypes);
      });

      uniquePlantTypes = uniquePlantTypes.filter(
        (size: any, index: any) => uniquePlantTypes.indexOf(size) === index
      );

      setColors(uniqueColors);
      setPotTypes(uniquePotTypes);
      setPlantTypes(uniquePlantTypes);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const renderHeader = (): JSX.Element => {
    return <components.Header goBack={true} title='Filter' />;
  };

  const renderLabels = (): JSX.Element => {
    return (
      <div style={{marginBottom: 30, gap: 10, ...utils.rowCenterWrap()}}>
        {labels.map((item, index) => {
          return (
            <span
              key={index}
              style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
              onClick={() => {
                dispatch((dispatch, getState) => {
                  const selectedCategories =
                    getState().filterSlice.selectedCategories;
                  if (selectedCategories.includes(item)) {
                    dispatch(
                      actions.setSelectedCategories(
                        selectedCategories.filter(
                          (category) => category !== item
                        )
                      )
                    );
                  } else {
                    dispatch(
                      actions.setSelectedCategories([
                        ...selectedCategories,
                        item,
                      ])
                    );
                  }
                });
              }}
            >
              <div
                style={{
                  margin: 0,
                  padding: '6px 18px',
                  display: 'flex',
                  borderRadius: 10,
                  border: selectedCategories.includes(item)
                    ? '1px solid transparent'
                    : '1px solid #E7EBEB',
                  backgroundColor: selectedCategories.includes(item)
                    ? theme.colors.pastelMint
                    : '#FAFAFA',
                }}
              >
                <text.T12
                  style={{
                    textTransform: 'uppercase',
                    color: selectedCategories.includes(item)
                      ? theme.colors.steelTeal
                      : theme.colors.mainColor,
                    ...theme.fonts.DM_Sans_700Bold,
                  }}
                >
                  {item}
                </text.T12>
              </div>
            </span>
          );
        })}
      </div>
    );
  };

  const renderRatings = (): JSX.Element => {
    return (
      <div style={{marginBottom: 30}}>
        <text.H5 style={{marginBottom: 14, color: theme.colors.mainColor}}>
          Rating
        </text.H5>
        <div style={{gap: 7, ...utils.rowCenterWrap()}}>
          {rating.map((item: any, index: number) => {
            return (
              <button
                key={index}
                style={{
                  padding: '5px 10px',
                  backgroundColor: selectedRatings.includes(item.rating)
                    ? theme.colors.pastelMint
                    : theme.colors.imageBackground,
                  borderRadius: 10,
                  border: selectedRatings.includes(item.rating)
                    ? '1px solid transparent'
                    : '1px solid #E7EBEB',
                  ...utils.rowCenter(),
                }}
                onClick={() => {
                  dispatch(
                    actions.setSelectedRatings(
                      selectedRatings.includes(item.rating)
                        ? selectedRatings.filter((e) => e !== item.rating)
                        : [...selectedRatings, item.rating]
                    )
                  );
                }}
              >
                <svg.RatingStarSvg
                  fillColor={
                    selectedRatings.includes(item.rating)
                      ? theme.colors.steelTeal
                      : theme.colors.transparent
                  }
                />
                <text.T12
                  style={{
                    ...theme.fonts.DM_Sans_700Bold,
                    fontSize: 12,
                    marginLeft: 4,
                    color: selectedRatings.includes(item.rating)
                      ? theme.colors.steelTeal
                      : theme.colors.textColor,
                  }}
                >
                  {item.title}
                </text.T12>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderColors = (): JSX.Element => {
    return (
      <div style={{marginBottom: 30}}>
        <text.H5 style={{marginBottom: 14, color: theme.colors.mainColor}}>
          Color
        </text.H5>
        <div style={{gap: 10, ...utils.rowCenterWrap()}}>
          {colors?.map((item: any, index: number) => {
            return (
              <button
                key={index}
                style={{
                  width: 29,
                  height: 29,
                  borderRadius: 15,
                  cursor: 'pointer',
                  borderStyle: 'solid',
                  ...utils.flexCenter(),
                  backgroundColor: item.code,
                  border: `1px solid ${
                    item.name === 'White' ? '#E7EBEB' : item.code
                  }`,
                }}
                onClick={() => {
                  dispatch((dispatch, getState) => {
                    const selectedColors =
                      getState().filterSlice.selectedColors;
                    if (selectedColors.includes(item.name)) {
                      dispatch(
                        actions.setSelectedColors(
                          selectedColors.filter((color) => color !== item.name)
                        )
                      );
                    } else {
                      dispatch(
                        actions.setSelectedColors([
                          ...selectedColors,
                          item.name,
                        ])
                      );
                    }
                  });
                }}
              >
                {selectedColors.includes(item.name) && (
                  <svg.CheckSvg
                    color={
                      item.name === 'White'
                        ? theme.colors.mainColor
                        : theme.colors.white
                    }
                  />
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPotTypes = (): JSX.Element => {
    return (
      <div style={{marginBottom: 30}}>
        <text.H5 style={{marginBottom: 14, color: theme.colors.mainColor}}>
          Pot Type
        </text.H5>
        <div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>
          {potTypes.map((item, index) => {
            return (
              <span
                key={index}
                style={{
                  display: 'inline-block',
                  padding: '5px 20px',
                  borderRadius: 8,
                  backgroundColor: selectedPotTypes.includes(item)
                    ? theme.colors.pastelMint
                    : theme.colors.imageBackground,
                  fontWeight: 'bold',
                  fontSize: 12,
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                  color: selectedPotTypes.includes(item)
                    ? theme.colors.steelTeal
                    : theme.colors.mainColor,
                  lineHeight: 1.7,
                  ...theme.fonts.DM_Sans_700Bold,
                  border: `1px solid ${
                    selectedPotTypes.includes(item)
                      ? theme.colors.pastelMint
                      : theme.colors.antiFlashWhite
                  }`,
                }}
                onClick={() => {
                  dispatch((dispatch, getState) => {
                    const selectedPotTypes =
                      getState().filterSlice.selectedPotTypes;
                    if (selectedPotTypes.includes(item)) {
                      dispatch(
                        actions.setSelectedPotTypes(
                          selectedPotTypes.filter((tag) => tag !== item)
                        )
                      );
                    } else {
                      dispatch(
                        actions.setSelectedPotTypes([...selectedPotTypes, item])
                      );
                    }
                  });
                }}
              >
                {item}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPlantTypes = (): JSX.Element => {
    return (
      <div style={{marginBottom: 30}}>
        <text.H5 style={{marginBottom: 14, color: theme.colors.mainColor}}>
          Type
        </text.H5>
        <div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>
          {plantTypes.map((item, index) => {
            return (
              <span
                key={index}
                style={{
                  display: 'inline-block',
                  padding: '5px 20px',
                  borderRadius: 8,
                  backgroundColor: selectedPlantTypes.includes(item)
                    ? theme.colors.pastelMint
                    : theme.colors.imageBackground,
                  fontWeight: 'bold',
                  fontSize: 12,
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                  color: selectedPlantTypes.includes(item)
                    ? theme.colors.steelTeal
                    : theme.colors.mainColor,
                  lineHeight: 1.7,
                  ...theme.fonts.DM_Sans_700Bold,
                  border: `1px solid ${
                    selectedPlantTypes.includes(item)
                      ? theme.colors.pastelMint
                      : theme.colors.antiFlashWhite
                  }`,
                }}
                onClick={() => {
                  dispatch((dispatch, getState) => {
                    const selectedPlantTypes =
                      getState().filterSlice.selectedPlantTypes;
                    if (selectedPlantTypes.includes(item)) {
                      dispatch(
                        actions.setSelectedPlantTypes(
                          selectedPlantTypes.filter((tag) => tag !== item)
                        )
                      );
                    } else {
                      dispatch(
                        actions.setSelectedPlantTypes([
                          ...selectedPlantTypes,
                          item,
                        ])
                      );
                    }
                  });
                }}
              >
                {item}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const renderButton = (): JSX.Element => {
    return (
      <components.Button
        title='apply filters'
        onClick={() => {
          navigate(-1);
        }}
      />
    );
  };

  const renderContent = (): JSX.Element => {
    if (isLoading) return <components.Loader />;

    return (
      <main style={{padding: '30px 20px 20px 20px', marginTop: 52}}>
        {renderLabels()}
        {renderRatings()}
        {renderColors()}
        {renderPotTypes()}
        {renderPlantTypes()}
        {renderButton()}
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
