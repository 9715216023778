import * as React from 'react';

export const UserSvg: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none'>
      <path
        fill='#CFF5CE'
        d='M13.333 14.5v-1.333a2.667 2.667 0 0 0-2.666-2.667H5.333a2.667 2.667 0 0 0-2.666 2.667V14.5'
      />
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M13.333 14.5v-1.333a2.667 2.667 0 0 0-2.666-2.667H5.333a2.667 2.667 0 0 0-2.666 2.667V14.5'
      />
      <path
        fill='#CFF5CE'
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M8 7.833A2.667 2.667 0 1 0 8 2.5a2.667 2.667 0 0 0 0 5.333Z'
      />
    </svg>
  );
};
