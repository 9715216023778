import React from 'react';
import {theme} from '../constants';
import {text} from '../text';

type Props = {
  item: any;
  numberOfLines?: number;
  style?: React.CSSProperties;
};

export const ProductSales: React.FC<Props> = ({item, style, numberOfLines}) => {
  return (
    <>
      {/* Render the product name */}

      {/* Conditionally render default_sales_type if it's 'Pack' */}
      {item.default_sales_type === 'PACK' && (
        <div
          style={{
            width: 60,
            backgroundColor: 'white',
            color: 'red',
            borderRadius: 30,
            padding: '5px 10px',
            marginTop: '5px',
            display: 'inline-block',
            fontSize: 13,
          }}
        >
          {item.default_sales_type}
        </div>
      )}
    </>
  );
};
