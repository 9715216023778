// services/notificationService.ts
export const fetchNotifications = async () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
  const token = sessionStorage.getItem('authToken');

  try {
    const response = await fetch(`${API_BASE_URL}/notifications`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch notifications');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Notification fetch error:', error);
    throw error;
  }
};
// services/notificationService.ts
export const markNotificationAsRead = async () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
  const token = sessionStorage.getItem('authToken');

  try {
    const response = await fetch(
      `${API_BASE_URL}/notifications/mark-all-read`,
      {
        method: 'Get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to mark notification as read');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Mark as read error:', error);
    throw error;
  }
};
