import {Link} from 'react-router-dom';
import React from 'react';

import {theme} from '../constants';
import {utils} from '../utils';

interface ButtonProps {
  title: string;
  onClick?: () => void;
  colorScheme?: 'dark' | 'light';
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export const Button: React.FC<ButtonProps> = ({
  title,
  style,
  onClick,
  colorScheme = 'dark',
  containerStyle,
}) => {
  return (
    <div style={{...containerStyle}}>
      <button
        onClick={onClick}
        style={{
          width: '100%',
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.coralRed : theme.colors.black,
          color:
            colorScheme === 'dark' ? theme.colors.white : theme.colors.white,
          height: 50,
          textAlign: 'center',
          fontSize: 14,
          transition: 'opacity 0.3s ease',
          borderRadius: 50,
          textTransform: 'capitalize',
          ...utils.flexCenter(),
          ...theme.fonts.DM_Sans_700Bold,
          ...style,
        }}
      >
        {title}
      </button>
    </div>
  );
};
