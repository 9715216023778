import React from 'react';
import {CSSProperties} from 'react';

import {utils} from '../utils';
import {theme} from '../constants';

type Props = {
  onClick?: () => void;
  numberOfLines?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const T16: React.FC<Props> = ({
  onClick,
  children,
  style = {},
  numberOfLines = 0,
}) => {
  return (
    <p
      style={{
        ...theme.fonts.DM_Sans_400Regular,
        fontSize: 16,
        lineHeight: 1.7,
        ...(numberOfLines
          ? (utils.numberOfLines(numberOfLines) as CSSProperties)
          : {}),
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

export default T16;
