import React from 'react';

import {svg} from '../assets/svg';
import {theme} from '../constants';
import {ProductType} from '../types';
import {actions} from '../store/actions';
import {hooks, RootState} from '../hooks';
import {useNavigate} from 'react-router-dom';

type Props = {
  version?: number;
  item: ProductType;
  style?: object;
};

export const ProductInCart: React.FC<Props> = ({item, style, version = 1}) => {
  const dispatch = hooks.useAppDispatch();
  const navigate = useNavigate();

  const cart = hooks.useAppSelector((state: RootState) => state.cartSlice.list);
  const exist = (item: ProductType) => cart.find((i) => i.id === item.id);
  const isSignedIn = Boolean(sessionStorage.getItem('authToken')); // Or use a state from Redux

  const strokeColor = theme.colors.mainColor;

  const handleAddToCart = (event: any) => {
    event.stopPropagation();

    if (!isSignedIn) {
      // Redirect to the sign-in page if not signed in
      navigate('/SignIn');
    } else {
      if (!exist(item)) {
        dispatch(actions.addToCart(item));
      }
    }
  };

  const handleRemoveFromCart = (event: any) => {
    event.stopPropagation();
    if (exist(item)) {
      dispatch(actions.removeFromCart(item));
    }
  };

  return (
    <div style={{display: 'flex', gap: '10px', ...style}}>
      {!exist(item) && (
        <button
          style={{
            zIndex: 1,
            borderRadius: 12,
            pointerEvents: 'auto',
            cursor: 'pointer',
            backgroundColor: theme.colors.mainColor,
            color: theme.colors.white,
            padding: '5px 10px',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleAddToCart}
        >
          <svg.AddSvg color={theme.colors.white} />
          <span style={{marginLeft: '5px'}}>Add to Cart</span>
        </button>
      )}

      {exist(item) && (
        <button
          style={{
            zIndex: 1,
            borderRadius: 12,
            pointerEvents: 'auto',
            cursor: 'pointer',
            backgroundColor: '#FF5B61', // You can customize the remove button color
            color: theme.colors.white,
            padding: '5px 10px',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleRemoveFromCart}
        >
          <svg.RemoveSvg color={theme.colors.white} />
          <span style={{marginLeft: '5px'}}>Remove from Cart</span>
        </button>
      )}
    </div>
  );
};
