import React from 'react';
import {CSSProperties} from 'react';

import {utils} from '../utils';
import {theme} from '../constants';

type Props = {
  style?: CSSProperties;
  numberOfLines?: number;
  children: React.ReactNode;
};

const H2: React.FC<Props> = ({
  style,
  children,
  numberOfLines = 0,
}): JSX.Element => {
  return (
    <h2
      style={{
        fontSize: 22,
        ...theme.fonts.Inter_700Bold,
        ...(numberOfLines
          ? (utils.numberOfLines(numberOfLines) as CSSProperties)
          : {}),
        ...style,
      }}
    >
      {children}
    </h2>
  );
};

export default H2;
