import React from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';

export const ForgotPassword: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderHeader = () => {
    return <components.Header title='Forgot password' goBack={true} />;
  };

  const renderContent = () => {
    return (
      <main style={{padding: '30px 20px 20px 20px', marginTop: 52}}>
        <text.T16 style={{marginBottom: 40, color: theme.colors.textColor}}>
          Please enter your email address. You will receive an OTP code.
        </text.T16>
        <custom.InputField
          label='Email'
          icon={<svg.InputCheckSvg />}
          placeholder='oliviabennett@mail.com'
          containerStyle={{marginBottom: 20}}
        />
        <components.Button
          title='send'
          onClick={() => {
            navigate('/NewPassword');
          }}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
