import axios from 'axios';
import React, {useEffect, useState} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {URLS} from '../config';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {components} from '../components';
import {utils} from '../utils';
export const EditProfile: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      const user = await axios.get(URLS.GET_USER).then((res) => res.data.user);

      setUser(user);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderHeader = () => {
    return <components.Header basket={true} goBack={false} />;
  };

  const renderFooter = (): JSX.Element => {
    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };

  const renderContent = () => {
    if (loading) return <components.TabLoader />;
    return (
      <main
        className='container'
        style={{
          flexGrow: 1, // This ensures the content takes the remaining space
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 40,
          paddingBottom: 20,
          marginTop: 180,
        }}
      >
        <div style={{marginBottom: 40, ...utils.rowCenter()}}>
          <img
            src={user.photo}
            style={{height: 60, width: 60, marginRight: 14}}
          />
          {/* <svg.CameraSvg />
          <button>
            <text.T14 style={{marginLeft: 10}}>Upload new photo</text.T14>
          </button> */}
        </div>
        <custom.InputField
          label='email'
          containerStyle={{marginBottom: 20}}
          placeholder='Olivia Bennett'
        />
        <custom.InputField
          label='password'
          containerStyle={{marginBottom: 20}}
          placeholder='zenithsneaks@mail.com'
        />
        <components.Button
          title='save changes'
          onClick={() => {
            navigate('/InfoSaved');
          }}
        />
      </main>
    );
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </div>
  );
};
