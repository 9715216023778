import {OrderItem} from './OrderItem';
import {ReviewItem} from './ReviewItem';
import {ProductCard} from './ProductCard';
import {ProfileItem} from './ProfileItem';
import {WishlistItem} from './WishlistItem';
import {PromocodeItem} from './PromocodeItem';
import {CaregoryProductCard} from './CatProductCard';
import {NotificationItem} from './NotificationItem';

export const items = {
  OrderItem,
  ReviewItem,
  ProductCard,
  ProfileItem,
  WishlistItem,
  PromocodeItem,
  CaregoryProductCard,
  NotificationItem,
};
