import React from 'react';

import {text} from '../../text';
import {hooks} from '../../hooks';
import {theme} from '../../constants';
import {components} from '../../components';

export const EmailVerified: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderContent = () => {
    return (
      <main
        className='full-height'
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 60px)',
          justifyContent: 'center',
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          alt='order successful'
          src={require('../../assets/icons/31.png')}
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            marginBottom: 14,
            color: theme.colors.mainColor,
            textTransform: 'capitalize',
            whiteSpace: 'pre-line',
          }}
        >
          Your email has been{'\n'}successfully verified!
        </text.H2>
        <text.T16
          style={{
            color: theme.colors.textColor,
            marginBottom: 30,
            whiteSpace: 'pre-line',
          }}
        >
          Now that you're officially a part of our{'\n'}community, you can enjoy
          all the features of our app.
        </text.T16>
        <components.Button
          title='Done'
          onClick={() => {
            navigate('/TabNavigator');
          }}
          containerStyle={{width: '100%'}}
        />
      </main>
    );
  };

  return renderContent();
};
