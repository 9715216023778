import React from 'react';

import {text} from '../../text';
import {hooks} from '../../hooks';
import {custom} from '../../custom';
import {svg} from '../../assets/svg';
import {theme} from '../../constants';
import {components} from '../../components';
import {useLocation} from 'react-router-dom';

export const SendPhoneOtp: React.FC = () => {
  let location = useLocation();
  const navigate = hooks.useAppNavigate();

  const renderHeader = (): JSX.Element => {
    return <components.Header goBack={true} title='Verify your phone number' />;
  };

  const renderContent = (): JSX.Element => {
    return (
      <main style={{padding: 20, marginTop: 52}}>
        <text.T16 style={{color: theme.colors.textColor, marginBottom: 40}}>
          We have sent you an SMS with a code to number: +17 0123456789.
        </text.T16>
        <custom.InputField
          label='phone number'
          icon={<svg.InputCheckSvg />}
          placeholder='+17123456789'
          containerStyle={{marginBottom: 20}}
        />
        <components.Button
          title={'Confirm'}
          onClick={() => {
            navigate('/Verification', {state: location.pathname});
          }}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
