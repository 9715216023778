import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {theme} from '../constants';
import {Typography} from '@mui/material';
import {fetchCategories} from '../services/categoryService';

type Props = {
  links?: {label: string; path: string}[];
  logo?: boolean;
  copyright?: boolean;
};

export const Footer: React.FC<Props> = ({links, logo, copyright}) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any[]>([]);
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categoriesData = await fetchCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error('Failed to load categories:', error);
      }
    };

    loadCategories();
  }, []);

  const renderLogo = (): JSX.Element | null => {
    if (logo) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <img
            src='https://mariaandsons.com/img/Icons/m&s_lgo.png' // Replace with your actual image path
            alt='Logo'
            style={{
              width: '190px',
              height: '110px',
              cursor: 'pointer',
              marginBottom: '10px',
            }}
            onClick={() => navigate('/Home')}
          />
          <Typography width={'250px'}>
            Explore, discover, and indulge in the joy of finding those special
            pieces that make you feel truly at home.
          </Typography>
        </div>
      );
    }

    return null;
  };

  const renderCategories = (): JSX.Element | null => {
    if (categories.length > 0) {
      return (
        <nav
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '5px',
          }}
        >
          <div style={{textAlign: 'left'}}>
            <Typography
              fontWeight={'bold'}
              fontSize={'20px'}
              marginBottom={'10px'}
            >
              Categories
            </Typography>
          </div>
          {categories.map((category) => (
            <div
              key={category.id}
              style={{position: 'relative'}}
              onMouseEnter={() => setHoveredCategory(category.name)}
              onMouseLeave={() => setHoveredCategory(null)}
            >
              <button
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  padding: '10px',
                  fontSize: '14px',
                  fontWeight:
                    hoveredCategory === category.name ? 'bold' : 'normal',
                  color: hoveredCategory === category.name ? 'red' : 'black',
                  position: 'relative',
                }}
                onClick={() => {
                  navigate('/shop', {
                    state: {products: category, title: 'Shop'}, // Adjust this as needed
                  });
                }}
              >
                {category.name}
                <span
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '2px',
                    backgroundColor: 'red',
                    width: hoveredCategory === category.name ? '100%' : '0%',
                    transition: 'width 0.3s ease',
                  }}
                />
              </button>
            </div>
          ))}
        </nav>
      );
    }

    return null;
  };

  const renderLinks = (): JSX.Element | null => {
    if (links) {
      return (
        <div style={{textAlign: 'left'}}>
          <Typography
            fontWeight={'bold'}
            fontSize={'20px'}
            marginBottom={'10px'}
          >
            Contact
          </Typography>
          <Typography>
            {'+251 925568081, SPIRIT HORIZON FZ-LLC, FAMC2641 Compass Building, Al Shohada Road, AL Hamra Industrial Zone-FZ Ras Al Khaimah, United Arab Emirates, info@mariaandsons.com'
              .split(',')
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text.trim()}
                  <br />
                </React.Fragment>
              ))}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const renderCopyright = (): JSX.Element | null => {
    if (copyright) {
      return (
        <div
          style={{
            fontSize: '14px',
            color: theme.colors.black,
            marginTop: '20px',
            gridColumn: '1 / -1',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          © {new Date().getFullYear()} Maria & Sons, Inc. All rights reserved.
          Design by Avetol Technologies
        </div>
      );
    }

    return null;
  };

  return (
    <footer
      style={{
        padding: '20px',
        backgroundColor: theme.colors.ghostWhite,
        borderTop: `1px solid ${theme.colors.aliceBlue2}`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '20px',
        alignItems: 'start',
      }}
    >
      {renderLogo()}
      {renderCategories()}
      {renderLinks()}
      {renderCopyright()}
    </footer>
  );
};
