import React, {FC} from 'react';
import {theme} from '../constants';
import {utils} from '../utils';

type Props = {
  type?: 'text' | 'password';
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clickable?: boolean;
  containerStyle?: React.CSSProperties;
  label?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  placeholder?: string;
  icon?: JSX.Element;
};

export const InputField: FC<Props> = ({
  value,
  onChange,
  placeholder,
  containerStyle,
  autoCapitalize = 'none',
  label,
  icon,
  clickable,
  type = 'text',
}) => {
  return (
    <div
      style={{
        height: 50,
        paddingLeft: 30,
        paddingRight: 20,
        borderRadius: 12,
        position: 'relative',
        border: '1px solid #E7EBEB',
        display: 'flex',
        alignItems: 'center',
        ...containerStyle,
      }}
    >
      {label && (
        <label
          style={{
            position: 'absolute',
            top: -8,
            left: 20,
            borderRadius: 12,
            padding: '0px 10px',
            backgroundColor: '#fff',
            fontSize: 12,
            color: theme.colors.textColor,
            textTransform: 'uppercase',
            ...theme.fonts.DM_Sans_500Medium,
          }}
        >
          {label}
        </label>
      )}
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoCapitalize={autoCapitalize}
        maxLength={50}
        type={type}
        style={{
          width: '100%',
          height: '100%',
          padding: 0,
          margin: 0,
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          fontSize: 16,
          color: theme.colors.mainColor,
          ...theme.fonts.DM_Sans_400Regular,
        }}
      />
      {icon && (
        <div style={{cursor: clickable ? 'pointer' : 'default'}}>{icon}</div>
      )}
    </div>
  );
};
