import React from 'react';

import {text} from '../text';
import {utils} from '../utils';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {PromocodeType} from '../types';

type Props = {
  isLast: boolean;
  item: PromocodeType;
};

export const PromocodeItem: React.FC<Props> = ({item, isLast}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#E7EBEB',
        borderRightWidth: 0,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: isLast ? 0 : 14,
        padding: 20,
        marginLeft: 20,
        cursor: 'pointer',
      }}
      onClick={() => {
        navigator.clipboard.writeText(item.code);
        alert('Promocode copied to clipboard');
      }}
    >
      <div style={{marginRight: 'auto', overflow: 'hidden', flex: 1}}>
        <div style={{...utils.rowCenter()}}>
          <svg.TagSvg />
          <text.H3 style={{marginLeft: 10, color: theme.colors.mainColor}}>
            {item.discount}% off
          </text.H3>
        </div>

        <span
          style={{
            display: 'block',
            color: theme.colors.textColor,
            ...theme.fonts.DM_Sans_400Regular,
            lineHeight: 1.5,
            fontSize: 14,
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Valid until {item.expiry}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <svg.CopySvg />
        <text.T12
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0,
            padding: 0,
            marginTop: 6,
            color: theme.colors.mainColor,
          }}
        >
          {item.code}
        </text.T12>
      </div>
    </div>
  );
};
