import React from 'react';
import {useNavigate} from 'react-router-dom';

import {text} from '../text';
import {theme} from '../constants';
import {components} from '../components';

export const DeleteAccount: React.FC = () => {
  const navigate = useNavigate();

  const renderContent = (): JSX.Element => {
    return (
      <main
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 80px - 20px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          src={require('../assets/icons/20.png')}
          alt='order successful'
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            color: theme.colors.mainColor,
            marginBottom: 14,
            textTransform: 'capitalize',
            whiteSpace: 'pre-line',
          }}
        >
          Are you sure?
        </text.H2>
        <text.T16
          style={{
            marginBottom: 30,
            whiteSpace: 'pre-line',
            color: theme.colors.textColor,
          }}
        >
          This action is irreversible.{'\n'}All your data will be lost.
        </text.T16>
        <components.Button
          title='Cancel'
          onClick={() => {
            navigate(-1);
          }}
          containerStyle={{marginBottom: 10}}
        />
        <components.Button
          title='Sure'
          colorScheme='light'
          onClick={() => {
            navigate('/');
          }}
        />
      </main>
    );
  };

  return <>{renderContent()}</>;
};
