import React, {useEffect} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {utils} from '../utils';
import {theme} from '../constants';
import {components} from '../components';

export const Checkout: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const cart = hooks.useAppSelector((state) => state.cartSlice.list);
  const total = hooks.useAppSelector((state) => state.cartSlice.total);
  const discount = hooks.useAppSelector((state) => state.cartSlice.discount);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textStyle = {
    margin: 0,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    color: theme.colors.textColor,
    ...theme.fonts.DM_Sans_400Regular,
  };

  const renderHeader = (): JSX.Element => {
    return <components.Header title='Checkout' goBack={true} line={true} />;
  };

  const renderMyOrder = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 15,
          margin: '0 20px 30px 20px',
          padding: 20,
          backgroundColor: '#FAFAFA',
        }}
      >
        <div
          style={{
            marginBottom: 20,
            paddingBottom: 20,
            borderBottom: `1px solid #E7EBEB`,
            ...utils.rowCenterSpcBtween(),
          }}
        >
          <text.H4
            style={{color: theme.colors.mainColor, textTransform: 'capitalize'}}
          >
            My order
          </text.H4>
          <text.H4 style={{color: theme.colors.mainColor}}>
            £{total.toFixed(2).replace('.', ',')}
          </text.H4>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* PRODUCTS */}
          {cart.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  ...theme.flex.rowCenterSpaceBetween,
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <h6
                  style={{
                    textTransform: 'capitalize',
                    margin: 0,
                    color: theme.colors.textColor,
                    fontSize: 14,
                    ...theme.fonts.DM_Sans_400Regular,
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  {item.name}
                  {item.size && `, ${item.size}`}
                  {item.color && `, ${item.color}`}
                </h6>
                <span
                  style={{
                    textTransform: 'capitalize',
                    margin: 0,
                    color: theme.colors.textColor,
                    fontSize: 14,
                    ...theme.fonts.DM_Sans_400Regular,
                    fontWeight: 400,
                    lineHeight: 1.5,
                  }}
                >
                  {item.quantity} x ${item.price}
                </span>
              </div>
            );
          })}
          {/* TOTAL */}
          {discount > 0 && (
            <div
              style={{
                display: 'flex',
                ...theme.flex.rowCenterSpaceBetween,
                marginBottom: 10,
              }}
            >
              <div style={{textTransform: 'capitalize'}}>Discount</div>
              <div style={{textTransform: 'capitalize'}}>{discount}%</div>
            </div>
          )}
          {/* DELIVERY */}
          <div style={{...utils.rowCenterSpcBtween()}}>
            <text.T14
              style={{
                textTransform: 'capitalize',
                color: theme.colors.textColor,
              }}
            >
              Delivery
            </text.T14>
            <text.T14
              style={{
                textTransform: 'capitalize',
                color: theme.colors.textColor,
              }}
            >
              $5
            </text.T14>
          </div>
        </div>
      </div>
    );
  };

  const renderInfo = (): JSX.Element => {
    return (
      <div
        style={{
          marginBottom: 30,
          backgroundColor: '#FAFAFA',
          padding: 20,
          margin: '0 20px 30px 20px',
        }}
      >
        <text.H5 style={{marginBottom: 13, color: theme.colors.mainColor}}>
          Shipping & payment info
        </text.H5>
        <div>
          <text.T14 style={{...textStyle, marginBottom: 10}}>
            Zenith Sneaks
          </text.T14>
          <text.T14 style={{...textStyle, marginBottom: 10}}>
            8000 S Kirkland Ave, Chicago, IL 6065...
          </text.T14>
          <text.T14 style={{...textStyle}}>**** 4864</text.T14>
        </div>
      </div>
    );
  };

  const renderButton = (): JSX.Element => {
    return (
      <components.Button
        title='Confirm order'
        onClick={() => {
          // navigate('/OrderSuccessful');
          navigate('/OrderFailed');
        }}
        containerStyle={{
          padding: '0 20px',
        }}
      />
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{paddingTop: 33, paddingBottom: 20, marginTop: 52}}
      >
        {renderMyOrder()}
        {renderInfo()}
        {renderButton()}
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
