import React from 'react';
import {useLocation} from 'react-router-dom';

import {text} from '../text';
import {theme} from '../constants';
import {components} from '../components';

export const Description: React.FC = () => {
  const location = useLocation();
  const item = location.state.item;

  const renderHeader = () => {
    return <components.Header goBack={true} title='Description' />;
  };

  const renderContent = () => {
    return (
      <main style={{padding: '52px 20px 20px 20px'}}>
        <div style={{height: 40}} />
        <text.H3 style={{marginBottom: 14, color: theme.colors.mainColor}}>
          {item.name}
        </text.H3>
        <text.T16 style={{color: theme.colors.textColor}}>
          {item.description}
        </text.T16>
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
