import React from 'react';

const rowCenter = (): React.CSSProperties => {
  return {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  };
};

export default rowCenter;
