import * as React from 'react';

export const DeleteSvg: React.FC = () => {
  return (
    <svg width={16} height={16} fill='none'>
      <g
        stroke='#23374A'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={0.8}
      >
        <path
          fill='#FF7575'
          fillOpacity={0.5}
          d='M8 14.667A6.667 6.667 0 1 0 8 1.334a6.667 6.667 0 0 0 0 13.333Z'
        />
        <path d='m10 6-4 4M6 6l4 4' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
