import {useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {animated, useSpring} from '@react-spring/web';

import {utils} from '../utils';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {hooks, RootState} from '../hooks';
import {items as ShopItem} from '../items';
import {fetchProductsByCategory} from '../services/categoryService';
import {ProductType} from '../types';

const sortingBy = [
  {id: 1, title: 'Sale'},
  {id: 2, title: 'Top'},
  {id: 3, title: 'Newest'},
  {id: 4, title: 'Price: low to high'},
  {id: 5, title: 'Price: high to low'},
];

export const ShopHome: React.FC = () => {
  const navigate = hooks.useAppNavigate();

  const location = useLocation();
  const title = location.state.title;
  const products = location.state.CategoryId;
  console.log(products);
  const [sort, setSort] = useState(sortingBy[0]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeChild, setActiveChild] = useState<number | null>(null);
  const [Catproducts, setCatProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  console.log(Catproducts);

  useEffect(() => {
    handleChildClick(products);
  }, [products.child]);
  const handleChildClick = async (categoryId: number) => {
    try {
      setActiveChild(categoryId);
      const productsData = await fetchProductsByCategory(products);
      setCatProducts(productsData.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const backdropAnimation = useSpring({
    opacity: modalIsOpen ? 1 : 0,
    display: modalIsOpen ? 'flex' : 'none',
  });

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    }

    if (!modalIsOpen) {
      document.body.style.overflow = 'auto';
    }
  }, [modalIsOpen]);

  const {
    selectedColors,
    selectedCategories,
    selectedPotTypes,
    selectedPlantTypes,
    selectedRatings,
  } = hooks.useAppSelector((state: RootState) => state.filterSlice);

  // const filteredProducts = products.filter((product: any) => {
  //   const potTypeMatch =
  //     selectedPotTypes.length === 0
  //       ? true
  //       : selectedPotTypes.some((potType) =>
  //           product.potTypes.includes(potType)
  //         );

  //   const plantTypeMatch =
  //     selectedPlantTypes.length === 0
  //       ? true
  //       : selectedPlantTypes.some((plantType) =>
  //           product.plantTypes?.includes(plantType)
  //         );

  //   const ratingMatch =
  //     selectedRatings.length === 0
  //       ? true
  //       : selectedRatings.some((rating) => product.rating >= rating);

  //   const colorMatch =
  //     selectedColors.length === 0
  //       ? true
  //       : selectedColors.some((selectedColor) =>
  //           product.colors.some(
  //             (productColor: any) => productColor.name === selectedColor
  //           )
  //         );

  //   const statusMatch =
  //     selectedCategories.length === 0
  //       ? true
  //       : (product.isNew && selectedCategories.includes('new')) ||
  //         (product.isTop && selectedCategories.includes('top')) ||
  //         (product.oldPrice && selectedCategories.includes('sale'));

  //   return (
  //     colorMatch && statusMatch && potTypeMatch && plantTypeMatch && ratingMatch
  //   );
  // });

  // const sortedProducts = [...filteredProducts].sort((a, b) => {
  //   switch (sort.title) {
  //     case 'Price: low to high':
  //       return a.price - b.price;
  //     case 'Price: high to low':
  //       return b.price - a.price;
  //     case 'Newest':
  //       return a.isNew === b.isNew ? 0 : a.isNew ? -1 : 1;
  //     case 'Top':
  //       return a.isTop === b.isTop ? 0 : a.isTop ? -1 : 1;
  //     case 'Sale':
  //       return a.oldPrice === b.oldPrice ? 0 : a.oldPrice ? -1 : 1;
  //     case 'Featured':
  //       return a.isFeatured === b.isFeatured ? 0 : a.isFeatured ? -1 : 1;
  //     default:
  //       return 0;
  //   }
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderTopHeader = (): JSX.Element => {
    return (
      <components.Header
        // title={title ? title : 'Shop'}
        navlink={true}
        // goBack={true}
        line={true}
        basket={true}
      />
    );
  };
  const renderFooter = (): JSX.Element => {
    return (
      <components.Footer
        logo={true}
        copyright={true}
        links={[
          {label: 'Home', path: '/Home'},
          {label: 'About', path: '/About'},
          {label: 'Services', path: '/Services'},
          {label: 'Contact', path: '/Contact'},
        ]}
      />
    );
  };

  const renderBottomHeader = (): JSX.Element => {
    return (
      <div
        style={{
          ...utils.rowCenterSpcBtween(),
          padding: '4px 20px 0 20px',
          marginTop: 52,
          height: 55,
        }}
      >
        <button
          onClick={() => {
            navigate('/Filter');
          }}
        >
          <svg.FiltersSvg />
        </button>
        <button
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <svg.SortingBySvg />
        </button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <header
        style={{
          top: 0,
          position: 'fixed',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.colors.white,
          zIndex: 4,
        }}
      >
        {renderTopHeader()}
        {renderBottomHeader()}
      </header>
    );
  };

  const renderContent = () => {
    if (loading) return <components.TabLoader />;

    return (
      <main style={{paddingBottom: 20, marginTop: 180, flex: 1}}>
        {/* Render the child categories at the top */}
        <div
          style={{
            padding: '0 20px',
            display: 'flex',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          }}
        >
          {products.child?.length > 0 &&
            products.child.map((childItem: any) => {
              const isActive = activeChild === childItem.id;

              return (
                <button
                  key={childItem.id}
                  onClick={() => handleChildClick(childItem.id)}
                  style={{
                    display: 'inline-block',
                    margin: '10px',
                    padding: '10px',
                    backgroundColor: isActive ? 'red' : 'transparent',
                    borderRadius: '30px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    border: isActive
                      ? `2px solid ${theme.colors.coralRed}`
                      : `2px solid ${theme.colors.black}`,
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                  }}
                >
                  <span
                    style={{
                      ...theme.fonts.DM_Sans_400Regular,
                      fontSize: 14,
                      color: isActive
                        ? theme.colors.white
                        : theme.colors.mainColor,
                    }}
                  >
                    {childItem.name}
                  </span>
                </button>
              );
            })}
        </div>
        {/* Render the products below the categories */}
        <div
          style={{
            ...utils.rowWrapSpcBtween(),
            marginTop: 30,
            marginLeft: 30,
          }}
        >
          {Catproducts.length > 0 ? (
            Catproducts.map((product: any) => (
              <ShopItem.CaregoryProductCard
                version={1}
                item={product}
                key={product.id}
              />
            ))
          ) : (
            <p>No products available.</p>
          )}
        </div>
      </main>
    );
  };

  const renderModal = (): JSX.Element => {
    return (
      <animated.div
        // className='container'
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: `${theme.colors.mainColor}50`,
          position: 'fixed',
          inset: 0,
          zIndex: 22,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          ...backdropAnimation,
        }}
        onClick={() => setIsOpen(false)}
      >
        <div
          style={{
            width: 'calc(100% - 80px)',
            backgroundColor: 'white',
            marginLeft: 20,
            marginRight: 20,
            borderRadius: 5,
            paddingTop: 10,
            paddingLeft: 20,
          }}
        >
          {sortingBy.map((item, index) => {
            return (
              <button
                style={{
                  width: '100%',
                  margin: 0,
                  marginLeft: 0,
                  paddingRight: 20,
                  borderBottom: '1px solid #E5E5E5',
                  paddingBottom: 15,
                  paddingTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSort(item);
                  setIsOpen(false);
                }}
              >
                <span
                  style={{
                    ...theme.fonts.DM_Sans_400Regular,
                    fontSize: 16,
                    color: theme.colors.mainColor,
                    lineHeight: 1.7,
                  }}
                >
                  {item.title}
                </span>

                <div
                  style={{
                    width: 16,
                    height: 16,
                    border: '1px solid #4A6973',
                    borderRadius: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {sort.id === item.id && (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: theme.colors.steelTeal,
                        borderRadius: 50,
                      }}
                    />
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </animated.div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {renderHeader()}
      {renderContent()}
      {renderModal()}
      {renderFooter()}
    </div>
  );
};
