import {FC} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {theme} from '../constants';
import {components} from '../components';

export const ForgotPasswordSentEmail: FC = () => {
  const navigate = hooks.useAppNavigate();

  const renderContent = (): JSX.Element => {
    return (
      <div
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 80px - 20px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          src={require('../assets/icons/24.png')}
          alt='order successful'
          style={{
            width: 123.39,
            marginBottom: 14,
          }}
        />
        <text.H2
          style={{
            color: theme.colors.mainColor,
            marginBottom: 14,
            whiteSpace: 'pre-line',
            textTransform: 'capitalize',
          }}
        >
          Your password has{'\n'}been reset!
        </text.H2>
        <text.T16
          style={{
            whiteSpace: 'pre-line',
            color: theme.colors.textColor,
            marginBottom: 30,
          }}
        >
          Log in with your new credentials.{'\n'}Welcome back!
        </text.T16>
        <components.Button
          title='done'
          onClick={() => {
            navigate('/SignIn');
          }}
        />
      </div>
    );
  };

  return renderContent();
};
