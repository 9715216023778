export const searchProducts = async (query: string) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
  const token = sessionStorage.getItem('authToken');

  try {
    const response = await fetch(`${API_BASE_URL}/search?search=${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch search results');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Search error:', error);
    throw error;
  }
};
