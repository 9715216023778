const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

interface SignInResponse {
  data: {
    token: string;
    user: {
      email: string;
      password: string;
    };
    // Add more fields as necessary
  };
  // Add more fields as necessary
}

export const signIn = async (
  email: string,
  password: string
): Promise<SignInResponse> => {
  try {
    const response = await fetch(`${API_BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email, password}),
    });

    if (!response.ok) {
      throw new Error('Failed to sign in');
    }

    const data: SignInResponse = await response.json();
    console.log(data);
    sessionStorage.setItem('authToken', data.data.token);

    return data;
  } catch (error) {
    console.error('Sign-in error:', error);
    throw error;
  }
};

// services/auth.ts

// services/auth.ts

export const registerUser = async (userData: {
  workplace_id: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

  try {
    const response = await fetch(`${API_BASE_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Failed to register user');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

// services/profileService.ts

export const fetchProfile = async (): Promise<any> => {
  const token = sessionStorage.getItem('authToken');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

  try {
    const response = await fetch(`${API_BASE_URL}/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch profile');
    }

    const data = await response.json();
    // Save profile info to local storage
    localStorage.setItem('profile', JSON.stringify(data.data));
    return data.data;
  } catch (error) {
    console.error('Profile fetching error:', error);
    throw error;
  }
};
