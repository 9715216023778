import axios from 'axios';
import React, {useEffect, useState} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {URLS} from '../config';
import {actions} from '../store/actions';
import {components} from '../components';

export const OrderHistoryEmpty: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();

  const [loading, setLoading] = useState<boolean>(true);

  const [productsData, setProductsData] = useState<any>([]);

  const getData = async () => {
    setLoading(true);

    try {
      const products = await axios
        .get(URLS.GET_PRODUCTS)
        .then((res) => res.data.products);

      setProductsData(products);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderHeader = () => {
    return <components.Header goBack={true} title='Order history' />;
  };

  const renderContent = () => {
    if (loading) return <components.Loader />;

    return (
      <main
        style={{
          padding: '52px 20px 20px 20px',
          height: 'calc(100vh - 80px - 20px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img
          alt='order successful'
          src={require('../assets/icons/18.png')}
          style={{width: 123.39, marginBottom: 14}}
        />
        <text.H2
          style={{
            marginBottom: 14,
            whiteSpace: 'pre-line',
            textTransform: 'capitalize',
          }}
        >
          Your order history is{'\n'}currently empty!
        </text.H2>
        <text.T16
          style={{
            whiteSpace: 'pre-line',
            marginBottom: 30,
          }}
        >
          Looks like you haven't placed any orders yet.{'\n'}Start shopping now
        </text.T16>
        <components.Button
          title='shop now'
          colorScheme='light'
          onClick={() => {
            dispatch(actions.resetFilters());
            navigate('/shop', {state: {products: productsData, title: 'Shop'}});
          }}
          containerStyle={{marginBottom: 10}}
        />
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
