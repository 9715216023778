import * as React from 'react';

export const LogOutSvg: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none'>
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M10.667 11.833 14 8.5l-3.333-3.333M14 8.5H6'
      />
      <path
        fill='#CFF5CE'
        d='M6 14.5H3.333A1.334 1.334 0 0 1 2 13.167V3.833A1.333 1.333 0 0 1 3.333 2.5H6'
      />
      <path
        stroke='#4A6973'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.2}
        d='M6 14.5H3.333A1.334 1.334 0 0 1 2 13.167V3.833A1.333 1.333 0 0 1 3.333 2.5H6'
      />
    </svg>
  );
};
