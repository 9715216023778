import {FC, useState} from 'react';
import {registerUser} from '../services/authService'; // Import the register service
import {text} from '../text';
import {hooks} from '../hooks';
import {utils} from '../utils';
import {custom} from '../custom';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {CircularProgress} from '@mui/material';

export const SignUp: FC = (): JSX.Element => {
  const navigate = hooks.useAppNavigate();
  const [workplaceId, setWorkplaceId] = useState('1'); // Assuming a default value for simplicity
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignUp = async () => {
    if (password !== passwordConfirmation) {
      alert("Passwords don't match");
      return;
    }

    setLoading(true);

    try {
      const response = await registerUser({
        workplace_id: workplaceId,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: passwordConfirmation,
      });
      console.log('Registration successful:', response);
      navigate('/SignUpAccountCreated');
    } catch (error) {
      console.error('Registration failed:', error);
      alert('Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderContent = (): JSX.Element => {
    return (
      <main
        className='container'
        style={{padding: '50px 20px 20px 20px', marginTop: 10}}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <img
            src='https://mariaandsons.com/img/Icons/m&s_lgo.png'
            alt='Logo'
            style={{width: '270px', height: '150px', cursor: 'pointer'}}
            onClick={() => navigate('/SignIn')}
          />
        </div>
        <text.H1
          style={{marginBottom: 40, display: 'flex', justifyContent: 'center'}}
        >
          Sign up
        </text.H1>
        <custom.InputField
          label='First Name'
          placeholder='Olivia'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <custom.InputField
          label='Last Name'
          placeholder='Bennett'
          icon={<svg.InputCheckSvg />}
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setLastName(e.target.value)}
        />
        <custom.InputField
          label='Email'
          icon={<svg.InputCheckSvg />}
          placeholder='oliviabennett@mail.com'
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setEmail(e.target.value)}
        />
        <custom.InputField
          clickable={true}
          label='Password'
          placeholder='••••••••'
          type='password'
          icon={<svg.EyeOffSvg />}
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setPassword(e.target.value)}
        />
        <custom.InputField
          clickable={true}
          type='password'
          label='Confirm Password'
          placeholder='••••••••'
          icon={<svg.EyeOffSvg />}
          containerStyle={{marginBottom: 20}}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        {loading ? (
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <CircularProgress color='inherit' />
          </div>
        ) : (
          <components.Button
            title='Sign up'
            onClick={handleSignUp}
            // loading={loading}
            style={{marginBottom: 20}}
          />
        )}
        <div style={{...utils.rowCenter(), marginBottom: 50}}>
          <text.T16 style={{marginRight: 4}}>Already have an account?</text.T16>
          <text.T16
            onClick={() => navigate('/SignIn')}
            style={{color: theme.colors.mainColor, cursor: 'pointer'}}
          >
            Sign in.
          </text.T16>
        </div>
      </main>
    );
  };

  return <>{renderContent()}</>;
};
