import * as React from 'react';

import {theme} from '../../constants';

type Props = {
  bgColor?: string;
  iconColor?: string;
  onClick?: () => void;
};

export const WishlistTabSvg: React.FC<Props> = ({
  onClick,
  bgColor = `${theme.colors.black}20`,
  iconColor = theme.colors.pastelMint,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={50}
      height={50}
      fill='none'
      onClick={onClick}
    >
      <circle cx={25} cy={25} r={25} fill={bgColor} />
      <path
        stroke={iconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M33.84 17.61a5.5 5.5 0 0 0-7.78 0L25 18.67l-1.06-1.06a5.501 5.501 0 0 0-7.78 7.78l1.06 1.06L25 34.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78v0Z'
      />
    </svg>
  );
};
