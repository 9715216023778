import {useState, FC} from 'react';
import {useLocation} from 'react-router-dom';

import {text} from '../../text';
import {utils} from '../../utils';
import {hooks} from '../../hooks';
import {theme} from '../../constants';
import {components} from '../../components';
import {actions} from '../../store/actions';

export const Verification: FC = () => {
  let {state} = useLocation();
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();

  const [inputs, setInputs] = useState<string[]>(['', '', '', '', '']);

  const renderHeader = (): JSX.Element => {
    return <components.Header goBack={true} title='Verification' />;
  };

  const renderDescription = (): JSX.Element => {
    return (
      <text.T16
        style={{
          marginBottom: 30,
          color: theme.colors.textColor,
          paddingRight: 20,
        }}
      >
        Enter your OTP code here.
      </text.T16>
    );
  };

  const renderInputFields = (): JSX.Element => {
    return (
      <div style={{...utils.rowCenterSpcBtween(), marginBottom: 30}}>
        {inputs.map((input, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              width: '17%',
              aspectRatio: 1 / 1,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              border: `1px solid #E7EBEB`,
            }}
          >
            <input
              maxLength={1}
              style={{
                textAlign: 'center',
                width: '100%',
                height: '100%',
                ...theme.fonts.DM_Sans_400Regular,
                borderRadius: 600 / 2,
                border: 'none',
                backgroundColor: theme.colors.transparent,
                fontSize: 20,
                color: theme.colors.mainColor,
              }}
              type='number'
              min={0}
              max={9}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderIfDidNotReceiveCode = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 30,
        }}
      >
        <text.T16
          style={{
            marginRight: 5,
            ...theme.fonts.DM_Sans_400Regular,
            fontSize: 16,
            color: theme.colors.textColor,
          }}
        >
          Didn’t receive the OTP?{' '}
          <button style={{marginRight: 5, color: theme.colors.mainColor}}>
            Resend.
          </button>
        </text.T16>
      </div>
    );
  };

  const renderButton = (): JSX.Element => {
    return (
      <components.Button
        title='verify'
        containerStyle={{marginBottom: 20}}
        onClick={() => {
          if (state === '/SendPhoneOtp') {
            dispatch(actions.setPhoneVerified(true));
            navigate('/PhoneVerified');
          }

          if (state === '/SendEmailOtp') {
            dispatch(actions.setEmailVerified(true));
            navigate('/EmailVerified');
          }
        }}
      />
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <main style={{flexGrow: 1, padding: 20, marginTop: 52}}>
        {renderDescription()}
        {renderInputFields()}
        {renderIfDidNotReceiveCode()}
        {renderButton()}
      </main>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
