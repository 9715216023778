import React, {useEffect} from 'react';

import {text} from '../text';
import {hooks} from '../hooks';
import {theme} from '../constants';
import {actions} from '../store/actions';
import {components} from '../components';

export const OrderFailed: React.FC = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();

  useEffect(() => {
    dispatch(actions.resetCart());
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <div
        style={{
          height: '100vh',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <img
          src={require('../assets/icons/29.png')}
          alt='order successful'
          style={{
            width: 123.39,
            marginBottom: 14,
          }}
        />
        <text.H2
          style={{
            color: theme.colors.mainColor,
            textTransform: 'capitalize',
            marginBottom: 14,
            whiteSpace: 'pre-line',
          }}
        >
          Sorry! Your order{'\n'}has failed!
        </text.H2>
        <text.T16
          style={{
            color: theme.colors.textColor,
            whiteSpace: 'pre-line',
            marginBottom: 30,
          }}
        >
          Something went wrong. Please try{'\n'}again to contimue your order.
        </text.T16>
        <components.Button
          title='try again'
          onClick={() => {
            dispatch(actions.setScreen('Order'));
            navigate('/TabNavigator');
          }}
          containerStyle={{width: '100%', marginBottom: 14}}
        />
        <components.Button
          title='go to my profile'
          onClick={() => {
            dispatch(actions.setScreen('Profile'));
            navigate('/TabNavigator');
          }}
          colorScheme='light'
          containerStyle={{width: '100%'}}
        />
      </div>
    );
  };

  return renderContent();
};
